import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {User, Role} from 'app/auth/models';
import {ToastrService} from 'ngx-toastr';
import {JwtHelperService} from "@auth0/angular-jwt";
import {Router} from "@angular/router";
import {CoreConfigService} from "../../../@core/services/config.service";
import {DeviceDetectorService} from 'ngx-device-detector';
import {AlertsService} from "./alerts.service";
import {TranslateService} from "@ngx-translate/core";
import {locale as german} from 'app/i18_services/de';
import {locale as english} from 'app/i18_services/en';
import {CoreTranslationService} from "../../../@core/services/translation.service";
import {userStatus} from "../models/userStatus";

declare const FB: any;

@Injectable({providedIn: 'root'})
export class AuthenticationService {

    public deviceInfo;
    public currentUser: Observable<User>;
    public helper = new JwtHelperService();
    currentUserSubject: BehaviorSubject<User>;
    lang: string;
    currentClient: any;

    /**
     *
     * @param _translateService
     * @param _coreTranslationService
     * @param _alertService
     * @param {HttpClient} _http
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     * @param _router
     * @param _coreConfigService
     * @param deviceService
     */
    constructor(private _translateService: TranslateService,
                private _coreTranslationService: CoreTranslationService,
                private _alertService: AlertsService,
                private _http: HttpClient,
                private _toastrService: ToastrService, private _router: Router,
                private _coreConfigService: CoreConfigService,
                private deviceService: DeviceDetectorService) {
        this._coreTranslationService.onSelectedLanguageChange.subscribe((selectedLanguage) => {
            this.lang = selectedLanguage;
        })
        this._coreTranslationService.translate(english, german);
        this.epicFunction();
        this.initializeFacebookSDK();
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

    }

    private initializeFacebookSDK(): void {
        // Initialize the Facebook SDK with your App ID
        if (FB) {
            FB.init({
                appId: environment._facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v12.0'
            });
        }
    }

    // getter: currentUserValue
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     *  Confirms if user is admin
     */
    isAdmin() {
        const role = this.currentUserSubject.value?.role?.toLowerCase();
        return role === Role.Admin.toLowerCase();
    }

    isSubscriber() {
        const role = this.currentUserSubject.value?.role?.toLowerCase();
        return role === Role.Subscriber.toLowerCase();
    }

    /**
     * check if logged in person is Mohammad Or Moamen
     */
    isAdmin2() {
        const role = this.currentUserSubject.value?.role?.toLowerCase();
        return (role === Role.SuperAdmin.toLowerCase() || role === Role.Admin.toLowerCase());
    }

    /**
     *  Confirms if user is client
     */
    isUser() {
        const role = this.currentUserSubject.value?.role?.toLowerCase();
        return role === Role.User.toLowerCase();
    }

    /**
     *  Confirms if user is company
     */
    isCompany() {
        const role = this.currentUserSubject.value?.role?.toLowerCase();
        return role === Role.Company.toLowerCase();
    }

    isSuperAdmin() {
        const role = this.currentUserSubject.value?.role?.toLowerCase();
        return role === Role.SuperAdmin.toLowerCase();
    }


    /**
     * User login
     *
     * @returns user
     * @param data
     */
    login(data: { email: string, password: string }) {
        return this._http
            .post<any>(`${environment.apiUrl}/auth/login`, data)
            .pipe(
                map(user => {
                    this.setAndDecodeCurrentUser(user, 1);
                })
            );
    }

    resetUserPassword(userEmail, userIP, userAdress) {
        return this._http
            .post<any>(`${environment.apiUrl}/user/reset-user-password`,
                {
                    userIP: userIP,
                    userAdress: userAdress,
                    userEmail: userEmail.email,
                    deviceInfo: this.deviceInfo
                })
            .pipe(
                map(response => {
                    return response;
                })
            );
    }


    validPasswordToken(token: string, email: string) {
        return this._http.post<any>(`${environment.apiUrl}/user/valid-password-token`, {
            email: email,
            resettoken: token
        });

    }

    /**
     * decode reset Token
     * @param tkn
     */
    getResetTokenData(tkn) {
        return {
            data: this.helper.decodeToken(tkn),
            expirationDate: this.helper.getTokenExpirationDate(tkn),
            isExpired: this.helper.isTokenExpired(tkn)
        }
    }

    /**
     * update password after forgotten it and token is valid
     * @param form
     */
    updateResetedPassword(form: { email: string; newPassword: string; }) {
        return this._http
            .post<any>(`${environment.apiUrl}/user/updateUserPasswordAfterReset`, form)
            .pipe(
                map(response => {
                    if (!response.error) {
                        setTimeout(() => {
                            this._alertService.onSuccessMessage(
                                this._translateService.instant('authentication.updateResetedPassword.onSuccessMsg_msg'),
                                this._translateService.instant('authentication.updateResetedPassword.title')
                            );
                        }, 1000);
                        this.removeCurrentUserInLocalStorage();
                        // this._router.navigate(['/authentication/login']);
                    } else {
                        this._alertService.onErrorMessage(this._translateService.instant('authentication.general.onError.msg'), this._translateService.instant('authentication.updateResetedPassword.title'));
                    }
                    return response;
                })
            );
    }

    register(newClient: User) {
        delete newClient.termsAccepted;
        return this._http
            .post<any>(`${environment.apiUrl}/users/create-user`, newClient)
            .pipe(
                map(user => {
                    // login successful if there's a jwt token in the response
                    this.setAndDecodeCurrentUser(user, 2);
                })
            );
    }


    decodeToken(token) {
        if (typeof token !== 'undefined' && token !== null)
            return this.helper.decodeToken(token);
    }

    /**
     * User logout
     *
     */
    logout() {
        return new Promise<void>((resolve, reject) => {
            this._http.get<any>(`${environment.apiUrl}/user/logout`).subscribe((response: any) => {
                if (response.error) {
                    setTimeout(() => {
                        this._alertService.onErrorMessage(
                            this._translateService.instant('authentication.general.onError.msg'),
                            this._translateService.instant('authentication.logout.title'))
                    }, 1500);

                } else {
                    this.removeCurrentUserInLocalStorage();
                    setTimeout(() => {
                        this._alertService.onSuccessMessage('👋 ' +
                            this._translateService.instant('authentication.logout.msg')
                            , 'Logout');
                    }, 1200);
                    this._router.navigate(['']);
                }
            }, reject);
        });
    }

    /**
     * Get current user
     */
    getUserById() {
        if (this.currentUserSubject) {
            return new Promise<void>((resolve, reject) => {
                this._http.get<any>(`${environment.apiUrl}/users/` + this.currentUserSubject.value.id).subscribe((response) => {
                    if (response.error) {
                        this._alertService.onErrorMessage(
                            this._translateService.instant('authentication.general.onError.msg'),
                            this._translateService.instant('authentication.general.onError.title'));
                    } else {
                        const resData = response.data;
                        this.storeCurrentUserInLocalStorage(resData)
                    }
                    resolve(response.data);
                }, reject);
            });

        }

    }


    /**
     * update gneral infos for current user
     * @param updatedClient
     */
    updateUser(updatedClient: any) {
        return new Promise((resolve, reject) => {
            updatedClient.lastUpdate = new Date().toISOString().slice(0, 19).replace('T', ' ');
            this._http.put<any>(`${environment.apiUrl}/updateuser`, updatedClient).subscribe((response: any) => {
                    if (response.error) {
                        this._alertService.onErrorMessage(this._translateService.instant('authentication.general.onError.msg'),
                            this._translateService.instant('authentication.profile_update.title'));
                    } else {
                        this.storeCurrentUserInLocalStorage(updatedClient);
                        setTimeout(() => {
                            this._alertService.onSuccessMessage(
                                this._translateService.instant('authentication.profile_update.onSuccessMsg_msg') + ' 🎉',
                                '👋 ' + this._translateService.instant('authentication.profile_update.title')
                            );
                        }, 1800);
                    }
                    resolve(response);
                }
                , reject);
        });

    }


    /**
     * set user profile picture ti null (reset)
     * @param email
     * @param updatedUserObj
     */
    resetProfilePicture( updatedUserObj: any) {

        return new Promise<void>((resolve, reject) => {
            this._http.put<any>(`${environment.apiUrl}/updateuser`, updatedUserObj).subscribe((response: any) => {
                if (response.error) {
                    this._alertService.onErrorMessage(this._translateService.instant('authentication.general.onError.msg'),
                        this._translateService.instant('authentication.resetProfilePicture.title'));
                } else {
                    this.storeCurrentUserInLocalStorage(updatedUserObj);
                    setTimeout(() => {
                        this._alertService.onSuccessMessage(
                            this._translateService.instant('authentication.resetProfilePicture.onSuccessMsg_msg') + ' 🎉',
                            '👋 ' + this._translateService.instant('authentication.resetProfilePicture.title')
                        );
                    }, 500);
                }
                resolve(response.data);
            }, reject);
        })

    }

    /**
     * update user data
     * @param userForm
     */

    updateUserPassword(userForm: { newPassword: string, password: string }): Observable<any> {
        return this._http.post<any>(`${environment.apiUrl}/user/update-user-password`, userForm).pipe(
            map(response => {
                if (!response.error) {
                    setTimeout(() => {
                        this._alertService.onSuccessMessage(
                            this._translateService.instant('authentication.updateUserPassword.onSuccessMsg_msg'),
                            this._translateService.instant('authentication.updateUserPassword.title')
                        );
                    }, 500);
                } else {
                    if (response.status === 401) {
                        this._alertService.onErrorMessage(
                            this._translateService.instant('authentication.updateUserPassword.errMsg'),
                            this._translateService.instant('authentication.updateUserPassword.title')
                        );
                    } else {
                        this._alertService.onErrorMessage(
                            this._translateService.instant('authentication.general.onError.msg'),
                            this._translateService.instant('authentication.updateUserPassword.title')
                        );
                    }
                }
                return response;
            }),
            catchError(error => {
                this._alertService.onErrorMessage(
                    this._translateService.instant(error),
                    this._translateService.instant('authentication.updateUserPassword.title')
                );
                return of({error: true, status: error.status});
            })
        );
    }

    deleteUser() {
        return new Promise<void>((resolve, reject) => {
            this._http.delete(`${environment.apiUrl}/users`).subscribe((response: any) => {
                if (response.error) {
                    this._alertService.onErrorMessage(
                        this._translateService.instant('authentication.general.onError.msg'), this._translateService.instant('authentication.deleteUser.title'));
                } else {
                    this.removeCurrentUserInLocalStorage();
                    setTimeout(() => {
                        this._alertService.onSuccessMessage(
                            this._translateService.instant('authentication.deleteUser.onSuccessMsg_msg'),
                            this._translateService.instant('authentication.deleteUser.title'));
                    }, 1000);
                    this._router.navigate(['/authentication/login']);
                }
                resolve();
            }, reject);
        });
    }

    routToDashboard() {
        this._router.navigate(['account/dashboard']);
    }

    /**
     * store currentUser in Localstorage
     * @param user
     */
    storeCurrentUserInLocalStorage(user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }

    /**
     * clear localstorage
     */
    removeCurrentUserInLocalStorage() {
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('token');

        const preferences = localStorage.getItem('cookiePreferences');
        localStorage.clear();
        localStorage.setItem('cookiePreferences', preferences);
        this.currentUserSubject.next(null);
    }

    setAppConfig() {
        this._coreConfigService.config = {
            layout: {
                navbar: {
                    hidden: false
                },
                menu: {
                    hidden: false
                },
                footer: {
                    hidden: false
                },
                enableLocalStorage: true
            }
        };
    }

    /**
     * get User Navigetor data (browser,device....)
     */
    epicFunction() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        // const isMobile = this.deviceService.isMobile();
        // const isTablet = this.deviceService.isTablet();
        // const isDesktopDevice = this.deviceService.isDesktop();

    }

    /**
     * get user Ip adress
     */
    getIPAddress() {
        return this._http.get('https://api.ipify.org/?format=json');
    }

    /**
     * get user location via IP address
     */
    getLocationData() {
        return this._http.get('https://ipapi.co/json/');
    }

    getToken() {
        return localStorage.getItem('token');
    }
    isAuthenticated(): boolean {
        const token = localStorage.getItem('token');
        return !(this.helper.isTokenExpired(token) || !token);

    }

    setUserToken(userToken) {
        localStorage.setItem('token', userToken);
    }

    onGoogleSignIn(data) {
        const userData = {
            social_id: data.sub,
            fullName: data.given_name + ' ' + data.family_name,
            email: data.email,
            role: Role.User,
            status: userStatus.Online,
            avatar: data.picture,
            isCompany: false,
            provider: 'google',
        };
        return this._http.post(`${environment.apiUrl}/auth/socialLogin`, userData);
    }

    signInWithFB2(): Observable<any> {
        return new Observable((observer) => {
            FB.login((response: any) => {
                if (response.authResponse) {
                    FB.api('/me?fields=name,email,picture', (data: any) => {
                        const userData = {
                            social_id: data.id,
                            fullName: data.name,
                            email: data.email,
                            role: Role.User,
                            status: userStatus.Online,
                            avatar: data.picture.data.url,
                            isCompany: false,
                            provider: 'facebook',
                        };

                        this._http.post(`${environment.apiUrl}/auth/socialLogin`, userData).subscribe(
                            (response: any) => {
                                this.setAndDecodeCurrentUser(response, response.isNewUser ? 2 : 1);
                                observer.next(response); // Emit the response to the observer
                                observer.complete(); // Complete the observable
                            },
                            (error) => {
                                this._alertService.onErrorMessage(this._translateService.instant('authentication.general.onError.msg'), this._translateService.instant('authentication.updateResetedPassword.title'));
                                observer.error(error); // Emit the error to the observer
                                observer.complete(); // Complete the observable
                            }
                        );
                    });
                } else {
                    setTimeout(() => {
                        this._alertService.onErrorMessage(
                            this._translateService.instant('general.onSocialErr.msgFB'),
                            this._translateService.instant('general.onSocialErr.title'))
                    }, 1500);

                    observer.complete(); // Complete the observable
                }
            }, {scope: 'public_profile,email'});
        });
    }


    /**
     * welcome toast
     * @private
     */
    private welcomeToastAfterRegister() {
        setTimeout(() => {
            this._toastrService.success(
                this._translateService.instant('authentication.register.onSuccessMsg', {role: this.currentClient.role}) + ' 🎉🎉🎉',
                '👋 ' + this._translateService.instant('authentication.register.title', {name: this.currentClient.fullName}),
                {toastClass: 'toast ngx-toastr', closeButton: true}
            );
        }, 2000);
    }

    private welcomeToastAfterLogin() {
        // Display welcome toast!
        setTimeout(() => {
            this._toastrService.success(
                this._translateService.instant('authentication.login.onSuccessMsg', {role: this.currentClient.role}) + ' 🎉🎉🎉',
                '👋 ' + this._translateService.instant('authentication.login.title', {name: this.currentClient.fullName}),
                {toastClass: 'toast ngx-toastr', closeButton: true}
            );
        }, 2000);
    }

    /**
     * set user and decode token
     * @param user
     * @param toastId
     */
    setAndDecodeCurrentUser(user, toastId) {
        if (user) {
            const userToken = user.token;
            this.currentClient = this.decodeToken(userToken).user;
            this.currentClient.token = userToken;
            this.setUserToken(userToken);
            // currentClient.status = userStatus.Online;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.storeCurrentUserInLocalStorage(this.currentClient);
            // Display welcome toast!
            switch (toastId) {
                //exist user
                case 1:
                    this.welcomeToastAfterLogin();
                    break;
                //new user
                case 2:
                    this.welcomeToastAfterRegister();
                    break;
            }
        }
        // notify
        this.setAppConfig();
        return this.currentClient;
    }
    
}
