import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class AlertsService {

    constructor(private _toastrService: ToastrService) {
    }

    onErrorMessage(message: string, title: string) {
        this._toastrService.error(message, title, {
            toastClass: 'toast ngx-toastr',
            closeButton: true
        });
    }


    onSuccessMessage(message: string, title: string) {
        this._toastrService.success(message, title,
            {toastClass: 'toast ngx-toastr', closeButton: true}
        );
    }

    onWarningMessage(message: string, title: string) {
        this._toastrService.warning(message, title, {
            toastClass: 'toast ngx-toastr',
            closeButton: true
        });
    }
}
