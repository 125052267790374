import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {EcommerceService} from 'app/main/apps/ecommerce/ecommerce.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {itemImagesPath} from "../../../../../environments/environment";
import {CoreTranslationService} from "../../../../../@core/services/translation.service";
import {locale as german} from 'app/main/apps/ecommerce/i18n/de';
import {locale as english} from 'app/main/apps/ecommerce/i18n/en';
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../../../auth/service";

@Component({
    selector: 'app-ecommerce-item',
    templateUrl: './ecommerce-item.component.html',
    styleUrls: ['./ecommerce-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'ecommerce-application'}
})
export class EcommerceItemComponent implements OnInit {
    // Input Decorotor
    @Input() product;
    @Input() isLoggedIn;
    @Input() isWishlistOpen = false;
    @Input() isEditable;
    isAdmin2: boolean ;
    lang: string;

    /**
     *
     * @param _coreTranslationService
     * @param {EcommerceService} _ecommerceService
     * @param modalService
     */
    constructor(private _coreTranslationService: CoreTranslationService, private _authenticationService: AuthenticationService,private _ecommerceService: EcommerceService, private modalService: NgbModal, private _translateService: TranslateService) {
        this._coreTranslationService.translate(english, german);
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle Wishlist
     *
     * @param product
     */
    toggleWishlist(product) {
        if (product.isInWishlist === true) {
            this._ecommerceService.removeFromWishlist(product.id).then(res => {
                product.isInWishlist = false;
            });
        } else {
            this._ecommerceService.addToWishlist(product.id).then(res => {
                product.isInWishlist = true;
            });
        }
    }


    // -----------------------------------------------------------------------------------------------------
    backendPath = itemImagesPath;

    ngOnInit(): void {
       this.isAdmin2= this._authenticationService.isAdmin2();
    }

    OnItemDelete() {
        Swal.fire({
            title: this._translateService.instant('alerts.onItemDelete.title', {item: this.product.title}),
            text: this._translateService.instant('alerts.onItemDelete.text'),
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: this._translateService.instant('alerts.onItemDelete.confirmButtonText'),
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1'
            },
        }).then((result) => {
            if (result.value) {
                this._ecommerceService.removeFromUserProductList(this.product.id).then((res: any) => {
                    if (res.error) {
                        Swal.fire({
                            title: this._translateService.instant('alerts.onError.confirmButtonText'),
                            showCloseButton: true,
                            text: this._translateService.instant('alerts.onError.text'),
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        });
                    }
                    this.product.isInWishlist = false;
                    Swal.fire({
                        title: this._translateService.instant('alerts.afterDelete.title'),
                        showCloseButton: true,
                        text: this._translateService.instant('alerts.afterDelete.text1') + this.product.title + +this._translateService.instant('alerts.afterDelete.text2'),
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-success'
                        }
                    }).then(
                        () => {
                            this._ecommerceService.getUserlist();
                        }
                    );
                })
            }
        });
    }
}
