import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from "../service";

@Injectable({
    providedIn: 'root'
})
export class CompanyAuthGuard implements CanActivate {
    constructor(private _router: Router, private _authenticationService: AuthenticationService) {
    }

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this._authenticationService.isAuthenticated()) {

            if (this._authenticationService.isAdmin()
                || this._authenticationService.isSuperAdmin()
                || this._authenticationService.isCompany()) {
                return true;
            } else {
                this._router.navigate(['/miscellaneous/not-authorized']);
                return false;
            }

        } else {
            this._router.navigate(['/authentication/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }

    }
}
