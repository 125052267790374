import {Component,  OnInit} from '@angular/core';
import {MessagesService} from "./messages.service";


@Component({
    selector: 'app-navbar-message',
    templateUrl: './navbar-message.component.html',
    styleUrls: ['./navbar-message.component.scss']
})
export class NavbarMessageComponent implements OnInit {

    public UnreadMsgLength = 0;


    constructor(private _messagesService:MessagesService) {
    }

    ngOnInit(): void {
       this._messagesService.onUnseenMsgsDataChange.subscribe((res)=>{
           this.UnreadMsgLength=res
       })
    }


}
