import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";
import {locale as german} from 'app/main/components/i18n/de';
import {locale as english} from 'app/main/components/i18n/en';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
@Component({
    selector: 'app-success-response',
    templateUrl: './success-response.component.html',
    styleUrls: ['./success-response.component.scss']
})
export class SuccessResponseComponent implements OnInit {

    @Input() msg;

    constructor(private _coreTranslationService: CoreTranslationService,private _router: Router,private _translateService: TranslateService) {
        this._coreTranslationService.translate(english, german);
    }

    ngOnInit(): void {
        this.runSuccessSwal();
    }


    runSuccessSwal() {
        Swal.fire({
            title: this._translateService.instant('success_response.title') +' ❤️',
            text: this.msg,
            icon: 'success',
            confirmButtonText: this._translateService.instant('buttons.confirmButtonText'),
            customClass: {confirmButton: 'btn btn-primary'},
            showCloseButton:true
        }).then((result)=>{
            this._router.navigate(['/items']);
        });
    }
}
