export const locale = {
    lang: 'de',
    data: {
        footer: {
            news_letter_text: "Tritt unserem Mailing-Liste bei, um die neuesten Nachrichten und Trends zu erhalten",
            subscribe: "Jetzt abonnieren",
            loading: "Laden...",
            pages: "Seiten",
            u_links: "Nützliche Links",
            social_Media: "Soziale Medien",
            links: {
                about: "Über uns",
                contact: "Kontakt",
                help: "Hilfe & FAQs",
                how_it_works: "Wie es funktioniert",
                blog: "Blog",
                list_item: "Artikel auflisten",
                explore: "Artikel erkunden",
                business: "Geschäft",
                privacy: "Datenschutz",
                terms: "Nutzungsbedingungen",
            }
        },
        dialog: {
            title: "Newsletter",
            text: "Vielen Dank für deine Anmeldung zu unserem Newsletter! Wir freuen uns, dich als Teil unserer Community begrüßen zu dürfen und werden dich regelmäßig über unsere neuesten Updates, Neuigkeiten und Angebote informieren"
        },
        general: {
            error_response: {
                title: 'Etwas ist schiefgelaufen!',
                text: "Ein Fehler ist aufgetreten, bitte versuche es erneut."
            },
        }
    }
};
