<!-- Item features starts -->
<div class="item-features">
    <div class="row text-center">
        <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
                <i data-feather="award"></i>
                <h4 class="mt-2 mb-1">{{'features.title1'|translate}}</h4>
                <p class="card-text">{{'features.text1'|translate}}</p>
            </div>
        </div>
        <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
                <i data-feather="clock"></i>
                <h4 class="mt-2 mb-1">{{'features.title2'|translate}}</h4>
                <p class="card-text">{{'features.text2'|translate}}</p>
            </div>
        </div>
        <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div class="w-75 mx-auto">
                <i data-feather="shield"></i>
                <h4 class="mt-2 mb-1">{{'features.title3'|translate}}</h4>
                <p class="card-text">{{'features.text3'|translate}}</p>
            </div>
        </div>
    </div>
</div>
<!-- Item features ends -->
