import {NgModule, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterStaticComponent} from "./footer-static.component";
import {CoreCommonModule} from "../../../../@core/common.module";
import {RouterModule} from "@angular/router";
import {Subject} from "rxjs";
import {CoreConfigService} from "../../../../@core/services/config.service";
import {takeUntil} from "rxjs/operators";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
    declarations: [FooterStaticComponent],
    imports: [RouterModule, CommonModule, CoreCommonModule, SweetAlert2Module, TranslateModule],
    exports: [FooterStaticComponent]
})
export class FooterStaticModule implements OnInit, OnDestroy {
    public coreConfig: any;

    private _unsubscribeAll: Subject<any>;


    constructor(public _coreConfigService: CoreConfigService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
