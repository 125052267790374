import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class StaticService {

    constructor(private _http: HttpClient) {
    }

    newsletterSubscribe(email) {
        return this._http.post<any>(`${environment.apiUrl}//subscribe`, email);
    }
}
