import {Component, OnInit} from '@angular/core';

import {locale as german} from 'app/main/components/cookies-dialog/i18n/de';
import {locale as english} from 'app/main/components/cookies-dialog/i18n/en';
import {CoreTranslationService} from "../../../../@core/services/translation.service";

@Component({
    selector: 'app-cookies-dialog',
    templateUrl: './cookies-dialog.component.html',
    styleUrls: ['./cookies-dialog.component.scss']
})
export class CookiesDialogComponent implements OnInit {

    isCookiesAccepted: boolean;

    constructor(private _coreTranslationService: CoreTranslationService,) {
        this._coreTranslationService.translate(english, german);
    }

    ngOnInit(): void {
        this.isCookiesAccepted = !this.showCookiesDialog();
    }

    onAccept() {
        this.isCookiesAccepted = true;
        localStorage.setItem('cookiePreferences', 'accepted');
    }

    showCookiesDialog(): boolean {
      const preferences = localStorage.getItem('cookiePreferences');
      return preferences === null || preferences !== 'accepted';
    }
}
