export const itemcategories = [
    {id: 1, label: "cameras", checked: false, icon: "home", image: "camera.png"},
    {id: 2, label: "fashion", checked: false, icon: "briefcase", image: "fashion.png"},
    {id: 3,  label:"family", checked: false, icon: "truck", image: "family.png"},
    {id: 4, label: "tools", checked: false, icon: "tool", image: "tools.png"},
    {id: 5, label: "home", checked: false, icon: "Hause", image: "home.png"},
    {id: 6, label: "emobility", checked: false, icon: "Hause", image: "emobility.png"},
    {id: 7, label: "drones", checked: false, icon: "Hause", image: "drones.png"},
    {id: 8, label: "electronics", checked: false, icon: "cpu", image: "electronics.png"},
    {id: 9, label: "sport", checked: false, icon: "Hause", image: "sport.png"},
    {id: 10, label: "events", checked: false, icon: "clipboard", image: "event.png"},
    {id: 11, label: "entertainment", checked: false, icon: "film", image: "entertainment.png"},
    {id: 12, label: "transport", checked: false, icon: "film", image: "transport.png"},
    {id: 13, label: "travel", checked: false, icon: "film", image: "travel.png"},
    {id: 14, label: "other", checked: false, icon: "plus-circle", image: "other.png"}
];
