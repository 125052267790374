import {Component, OnInit} from '@angular/core';

import {NotificationsService} from 'app/layout/components/navbar/navbar-notification/notifications.service';
import {NotificationsMainService} from "../../../../main/apps/notifications/notifications-main.service";
import {profileImagesPath} from "../../../../../environments/environment";
import {Router} from "@angular/router";
import {locale as german} from 'app/layout/components/i18n/de';
import {locale as english} from 'app/layout/components/i18n/en';
import {CoreTranslationService} from "../../../../../@core/services/translation.service";
import {NotificationsI18nService} from "../../../../main/utils/notifications_i18n/notifications-i18n.service";

@Component({
    selector: 'app-navbar-notification',
    templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
    // Public
    public notificationsArr;
    unSeenNotifications = 0;
    backendServerPath = profileImagesPath;

    /**
     *
     * @param {NotificationsService} _notificationsService
     */
    constructor(private _translateNotification:NotificationsI18nService, private _coreTranslationService: CoreTranslationService, private _router: Router, private _notificationsService: NotificationsMainService) {
        this._coreTranslationService.translate(english, german);
        this._notificationsService.getUserNotifications();
    }


    /**
     * On init
     */
    ngOnInit(): void {

        this._notificationsService.onNotificationsChanged.subscribe((res) => {
               this.notificationsArr = res;
        })

        this._notificationsService.onUnreadInboxCountChanged.subscribe((res) => {
            this.unSeenNotifications = res;
        })

    }

    openNotification(id) {
        this._notificationsService.openNotificationDetails(id);
    }

    onReadAllMessages() {
        this.unSeenNotifications = 0;
        this._router.navigate(['/account/notifications']);
    }

    getNotificationTitle(notification) {
       const notificationData= this._translateNotification.getTranslatedNotification(notification);
       return  notificationData.title;

    }

    getNotificationContent(notification: any) {
        const notificationData= this._translateNotification.getTranslatedNotification(notification);
        return  notificationData.text;
    }
}

