<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

    </div>
    <ul class="nav navbar-nav align-items-center ml-auto">
        <!-- Language selection -->
      <app-navbar-language></app-navbar-language>
        <!--/ Language selection -->

        <!-- Toggle skin -->
        <li class="nav-item d-none d-lg-block">
            <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
                      class="ficon font-medium-5 feather"></span>
            </a>
        </li>
        <!--/ Toggle skin -->

        <!-- Search -->
        <app-navbar-search></app-navbar-search>
        <!--/ Search -->

        <!-- Bookmark -->
        <app-navbar-bookmark></app-navbar-bookmark>
        <!--/ Bookmark -->
        <!-- Messages -->
        <app-navbar-message></app-navbar-message>
        <!--/ Messages -->

        <!-- Notification -->
        <app-navbar-notification></app-navbar-notification>
        <!--/ Notification -->

        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user">
            <a
                    class="nav-link dropdown-toggle dropdown-user-link"
                    id="dropdown-user"
                    ngbDropdownToggle
                    id="navbarUserDropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                <ng-container *ngIf="this.currentUser">
                    <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder"
            >{{ this.currentUser.fullName }}</span
            ><span class="user-status">{{ this.currentUser.role }}</span>
                    </div>

                    <span class="avatar" *ngIf="currentUser.avatar"
                    ><img
                            class="round"
                            [src]="avatarImageSrc"
                            alt="{{this.currentUser.fullName}}-avatar"
                            height="40"
                            width="40"/><span [ngClass]="{
            'avatar-status-offline': currentUser.status == 'offline',
            'avatar-status-online': currentUser.status == 'online',
            'avatar-status-busy': currentUser.status == 'busy',
            'avatar-status-away': currentUser.status == 'away'
          }"></span
                    ></span>

                    <span class="avatar" *ngIf="!currentUser.avatar"
                    >  <div style="width: 40px;height: 40px"
                            class="avatar-content">{{currentUser.fullName | titlecase | initials }}</div><span
                            [ngClass]="{
            'avatar-status-offline': currentUser.status == 'offline',
            'avatar-status-online': currentUser.status == 'online',
            'avatar-status-busy': currentUser.status == 'busy',
            'avatar-status-away': currentUser.status == 'away'
          }"></span
                    ></span>
                </ng-container>
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
                <a ngbDropdownItem [routerLink]="['/account/account-settings']"
                ><span [data-feather]="'settings'" [class]="'mr-50'"></span>{{'navbar.menu.account'|translate}}</a>
                <a ngbDropdownItem [routerLink]="['/faq']"
                ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span>{{'navbar.menu.help'|translate}}</a>
                <div class="dropdown-divider"></div>
                <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span>
                    {{'navbar.menu.logout'|translate}}</a>
            </div>
        </li>
        <!--/ User Dropdown -->
    </ul>
</div>
