import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavHeaderStaticComponent} from "./nav-header-static.component";
import {RouterModule} from "@angular/router";
import {CoreCommonModule} from "../../../../@core/common.module";
import {NavbarModule} from "../../../layout/components/navbar/navbar.module";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
    declarations: [NavHeaderStaticComponent],
    imports: [RouterModule, CommonModule, CoreCommonModule, NavbarModule, TranslateModule],
    exports: [NavHeaderStaticComponent]
})
export class NavHeaderStaticModule {
}
