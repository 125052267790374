export const locale = {
    lang: 'en',
    data: {
        labels: {
            pictures: ' Pictures',
            picture_sub_title: 'Upload, drag and drop images',
            allowed_images: '*Allowed JPG or PNG images',
            allowed_number_images: '*Number of images must be between 1 and 4',
        },
        onWarningMessage: {
            title: 'Max 4 images!',
            text: 'Max 4 images are allowed!',
            text2: "Upload at least one image!"
        },
        onErrorMessage: {
            title: 'Upload images',
            text: 'JPG or PNG images are allowed!'
        }

    }
};
