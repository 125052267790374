import { Component, OnInit } from '@angular/core';
import {locale as german} from 'app/main/components/i18n/de';
import {locale as english} from 'app/main/components/i18n/en';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-items-features',
  templateUrl: './items-features.component.html',
  styleUrls: ['./items-features.component.scss']
})
export class ItemsFeaturesComponent implements OnInit {

  constructor(private _coreTranslationService: CoreTranslationService,private _translateService: TranslateService) {
    this._coreTranslationService.translate(english, german);
  }

  ngOnInit(): void {
  }

}
