<footer id="newsletter">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="section-heading">
                    <h4>{{'footer.news_letter_text'|translate}}</h4>
                </div>
            </div>


            <div class="col-lg-6 offset-lg-3">
                <form id="search" [formGroup]="emailForm" (ngSubmit)="onSubscribe()">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <fieldset>
                                <input formControlName="email" type="text" name="email" class="email"
                                       placeholder="Email Address..."
                                       autocomplete="on" required>
                                <div *ngIf="submitted && emailForm.get('email').invalid && emailForm.get('email').touched"
                                     class="error">
                                    Email is required
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                                <button *ngIf="!loading" type="submit"
                                        class="main-button">
                                    <span>{{'footer.subscribe'|translate}}</span>
                                    <i class="fa fa-angle-right"></i></button>
                                <button *ngIf="loading" type="submit" class="main-button">
                                    <div class="text-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">{{'footer.loading'|translate}}</span>
                                        </div>
                                    </div>
                                </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="footer-widget">
                    <h4>{{'footer.pages'|translate}}</h4>
                    <ul>
                        <li><a routerLink="/about">{{'footer.links.about'|translate}}</a></li>
                        <li><a routerLink="/contact-us">{{'footer.links.contact'|translate}}</a></li>
                        <li><a routerLink="/faq">{{'footer.links.help'|translate}}</a></li>
                        <li><a routerLink="/how-it-works">{{'footer.links.how_it_works'|translate}}</a></li>
                        <li><a routerLink="/blog">{{'footer.links.blog'|translate}}</a></li>
                    </ul>

                </div>
            </div>
            <div class="col-lg-4">
                <div class="footer-widget">
                    <h4>{{'footer.u_links'|translate}}</h4>
                    <ul>
                        <li><a routerLink="/account/list-an-item">{{'footer.links.list_item'|translate}}</a></li>
                        <li><a routerLink="/items">{{'footer.links.explore'|translate}}</a></li>
                        <li><a routerLink="/business">{{'footer.links.business'|translate}}</a></li>
                    </ul>
                    <ul>
                        <li><a routerLink="/privacy-policy">{{'footer.links.privacy'|translate}}</a></li>
                        <li><a routerLink="/terms-of-use">{{'footer.links.terms'|translate}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="footer-widget">
                    <h4>{{'footer.social_Media'|translate}}</h4>
                    <div class="logo">
                        <img style="width: 150px;height: 62px" src="../../../../assets/images/logo/logo-white.png"
                             alt="Colshab-{{'footer.social_Media'|translate}}">
                    </div>
                    <p>Consume less share better...</p>
                    <div class="justify-content-between py-1 my-1 border-top">
                        <a href="{{colshabFacebook}}" target="_blank">
                            <button type="button"
                                    class="btn mr-1 btn-icon btn-icon rounded-circle btn-social-media"
                                    rippleEffect>
                                <span [data-feather]="'facebook'"></span></button>
                        </a>
                        <a href="{{colshabInstagram}}" target="_blank">
                            <button type="button"
                                    class="btn mr-1 btn-icon btn-icon rounded-circle btn-social-media"
                                    rippleEffect>
                                <span [data-feather]="'instagram'"></span></button>
                        </a>
                        <a href="{{colshabTwitter}}" target="_blank">
                        <button type="button"
                                class="btn btn-icon btn-icon rounded-circle btn-social-media"
                                rippleEffect>
                            <span [data-feather]="'twitter'"></span></button>
                        </a>

                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="copyright-text">
                    <p>Copyright © {{currentYear}}  <a class="ml-25" href="https://colshab.com"
                                                       target="_blank">Colshab</a> . All Rights Reserved.</p>
                </div>
            </div>

        </div>
    </div>
</footer>

