<ngx-dropzone class="custom-dropzone" ngx-dropzone accept="image/jpeg,image/jpg,image/png" (change)="onSelect($event)" [expandable]="true"
     [disableClick]="files.length>=4">
    <img src="../../../../../assets/images/new/add_new_image.svg" style="width: 120px;height: 120px" alt="Upload Image"
         *ngIf="files.length">
    <ngx-dropzone-label *ngIf="!files.length">
        <div>
            <img src="../../../../../assets/images/new/add_new_image.svg"
                 style="width: 120px;height: 120px" alt="add_new_image">
            <p class="text-muted font-small-2"> {{'labels.allowed_images'|translate}} </p>
            <p class="text-muted font-small-2"> {{'labels.allowed_number_images'|translate}}</p>
        </div>
    </ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true"
                                (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f?.name }} ({{ f?.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
</ngx-dropzone>
