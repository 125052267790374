import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'localenumber'
})
export class LocalenumberPipe implements PipeTransform {
    /**
     * return local number with max 2 decimal (fractionDigits)
     * @param value
     * @param lang
     */
    transform(value: number, lang: string, minimumFractionDigits?: number) {
        const minimumFractionDigitsNew = minimumFractionDigits ? minimumFractionDigits : 1;
        return value.toLocaleString(lang, {
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: 2,
        });

        // if (value){
        //     return new Intl.NumberFormat(lang, {
        //         minimumFractionDigits: minimumFractionDigits?minimumFractionDigits:1,
        //         maximumFractionDigits: 2
        //     }).format(value);
        // }
        // return '';

    }
}
