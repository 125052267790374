<div id="err_msg">
        <div class="after_card">
            <i class="checkmark">x</i>
        </div>
        <div class="pt-2">
            <h1>{{title}}</h1>
            <p>{{text}}</p>
            <div class="col-12 pt-2">
                <button (click)="goBack()" rippleEffect class="btn btn-primary mr-1">{{'buttons.try_again'|translate}}</button>
                <button routerLink="/items" rippleEffect class="btn btn-outline-secondary">{{'buttons.explore_items'|translate}}</button>
            </div>
        </div>

</div>
