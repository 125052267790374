export const locale = {
    lang: 'en',
    data: {
        features: {
            title1: "Local Sharing",
            text1: "Access a variety of items to borrow or lend to other members of your local community.",
            title2: "Reduce Waste Together",
            text2: "Save money and reduce waste by sharing your seldom-used items with others who need them.",
            title3: "Connect and Share",
            text3: "Connect with like-minded individuals to share your hobbies, equipment, and experiences."
        },
        contact_us: {},
        success_response: {
            title: 'Thank you',

        },
        error_response: {
            title: 'Somthing went wrong!',
            text: "You won't be able to revert this!",
        },
        success_response_no_popup: {
            title: 'Success',
        },
        error_response_no_popup: {
            title: 'Somthing went wrong!',
        },
        buttons: {
            confirmButtonText: 'Ok',
            back: 'Back Home',
            explore_items: 'Explore Items',
            try_again: 'Try Again'
        }
    }
};
