import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import Swal from "sweetalert2";
import {locale as german} from 'app/main/components/i18n/de';
import {locale as english} from 'app/main/components/i18n/en';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-error-response',
    templateUrl: './error-response.component.html',
    styleUrls: ['./error-response.component.scss']
})
export class ErrorResponseComponent implements OnInit {

    constructor( private _translateService: TranslateService,private _router: Router,private _coreTranslationService: CoreTranslationService) {
        this._coreTranslationService.translate(english, german);
    }

    ngOnInit(): void {
        this.runErrSwal();
    }


    runErrSwal() {
        Swal.fire({
            title:  this._translateService.instant('error_response.title'),
            text:  this._translateService.instant('error_response.text'),
            icon: 'error',
            confirmButtonText: this._translateService.instant('buttons.confirmButtonText'),
            customClass: {confirmButton: 'btn btn-primary'},
            showCloseButton: true,
        }).then((result) => {
            this._router.navigate(['/items']);
        });
    }
}
