<div class="badge badge-pill badge-glow"
     [ngClass]="{
                  'badge-light-primary': status == 'Pending',
                  'badge-light-success': status == 'Accepted',
                  'badge-light-dark': status == 'Completed',
                  'badge-light-danger': status == 'Rejected',
                  'badge-light-warning': status == 'Cancelled',
                  'badge-light-info': status == 'Rented'
                }"
>
    <ng-template #tipContent3>
        <span *ngIf="status == 'Pending' ">{{'order_status.pending'|translate}}</span>
        <span *ngIf="status == 'Accepted' ">{{'order_status.accepted'|translate}}</span>
        <span *ngIf="status == 'Completed' ">{{'order_status.completed'|translate}}</span>
        <span *ngIf="status == 'Rejected' ">{{'order_status.rejected'|translate}}</span>
        <span *ngIf="status == 'Cancelled' ">{{'order_status.cancelled'|translate}}</span>
        <span *ngIf="status == 'Rented' ">{{'order_status.rented'|translate}}</span>
    </ng-template>
    <span class="avatar-content" placement="top" [ngbTooltip]="tipContent3"
          container="body">  {{ status }} </span>
</div>
