import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from 'environments/environment';
import {AuthenticationService} from 'app/auth/service';
import {CoreConfigService} from "../../../@core/services/config.service";

@Injectable()
export class BackEndDefaultSettingsInterceptor implements HttpInterceptor {
    /**
     * Creates an instance of this class.
     * @param _coreConfigService
     */
    constructor(private _coreConfigService: CoreConfigService) {
    }

    /**
     * Add auth header with jwt if user is logged in and request is to api url
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const userLanguage = this._coreConfigService.getDefaultLanguage();

        request = request.clone({
            setHeaders: {'X-User-Language': userLanguage}
        });

        return next.handle(request);
    }

}
