export const locale = {
    lang: 'en',
    data: {
        part_1:'Do you like cookies?',
        part_2:'We use cookies to ensure you get the best experience on our website.',
       buttons:{
           btn1:'Learn more',
           btn2:'accept',
       }
    }
};
