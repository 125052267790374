export enum userStatus {
    Active = 'active',
    InActive = 'inactive',
    Online = 'online',
    Offline = 'offline',
    Busy = 'busy',
    Away = 'away'


}
