import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from "../../../auth/service";
import {environment} from "../../../../environments/environment";
import {MessagesService} from "../../../layout/components/navbar/navbar-message/messages.service";
import {AlertsService} from "../../../auth/service/alerts.service";
import {locale as german} from 'app/i18_services/de';
import {locale as english} from 'app/i18_services/en';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {TranslateService} from '@ngx-translate/core';
import {Socket} from "ngx-socket-io";

@Injectable()
export class ChatService {
    public loggedInUserContacts: any[] = [];
    public chats: any[];
    public currentUser;
    public isChatOpen: Boolean;
    public selectedChat;
    public selectedChatUser;
    public onContactsChange: BehaviorSubject<any>;
    public onChatsChange: BehaviorSubject<any>;
    public onSelectedContactPersonChange: BehaviorSubject<any>;
    public onChatUsersChange: BehaviorSubject<any>;
    public onChatOpenChange: BehaviorSubject<Boolean>;
    public onUserProfileChange: BehaviorSubject<any>;

    constructor(private _router: Router, private _httpClient: HttpClient, private _authenticationService: AuthenticationService,
                private socket: Socket, private _route: ActivatedRoute,
                private _coreTranslationService: CoreTranslationService, private _translateService: TranslateService,
                private _messagesService: MessagesService, private _alertService: AlertsService) {
        this._coreTranslationService.translate(english, german);
        this.onUserProfileChange = new BehaviorSubject([]);
        this.currentUser = this._authenticationService.currentUserValue;
        this.onUserProfileChange.next(this.currentUser);
        this.isChatOpen = false;
        this.onContactsChange = new BehaviorSubject([]);
        this.onChatsChange = new BehaviorSubject([]);
        this.onSelectedContactPersonChange = new BehaviorSubject({});
        this.onChatUsersChange = new BehaviorSubject([]);
        this.onChatOpenChange = new BehaviorSubject(false);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getUserProfile(),
                this.getContacts(),
                this.connectToSocket()
            ]).then(() => {
                resolve();
            }, reject);
        });
    }

    private connectToSocket() {
        const userId = this.getUserIdFromToken();
        if (userId) {
            this.socket.connect(); // Ensure socket connection
            this.socket.emit('join', userId);
        }
    }

    private getUserIdFromToken(): number | null {
        const token = this.getToken();
        if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            return decodedToken.user.id;
        }
        return null;
    }

    getToken() {
        return localStorage.getItem('token');
    }

    joinRoom(userId: any) {
        this.socket.emit('join', userId);
    }

    /**
     * Get Contacts
     */
    getContacts(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((`${environment.apiUrl}/contacts`)).subscribe((response: any) => {
                this.loggedInUserContacts = response.data;
                this.onContactsChange.next(this.loggedInUserContacts);
                resolve(this.loggedInUserContacts);
            }, reject);
        });
    }

    /**
     * Get User Profile
     */
    getUserProfile(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._authenticationService.currentUser?.subscribe((x) => {
                this.currentUser = x;
                this.onUserProfileChange.next(this.currentUser);
                resolve(this.currentUser);
            }, reject);
        });
    }

    /**
     * Get Selected Chat User
     * @param userId
     */
    getSelectedChatUser(userId) {
        this.selectedChatUser = this.loggedInUserContacts.find(contact => contact.id === userId);
        this.onSelectedContactPersonChange.next(this.selectedChatUser);
    }


    /**
     * Selected Chats
     *
     * @param id
     */
    selectedChats(id: number): void {
        // Find the chat by userId
        const existingChat = this.chats?.find(chat => chat.userId === id);

        if (existingChat) {
            // If chat exists, select it
            this.selectedChat = existingChat;
        } else {
            // If chat doesn't exist, create a new chat
            this.selectedChat = null;
        }

        // Fetch the selected chat user
        this.getSelectedChatUser(id);
        // /update
        // this.updateQueryParam(id);

    }

    /**
     * Function to update or remove query parameters
     * @param id
     */
    updateQueryParam(id) {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {c_chat: id},
            queryParamsHandling: 'merge' // Preserve other query params
        });
    }

    /**
     * Open Chat
     *
     * @param id
     */
    openChat(id) {
        this.isChatOpen = true;
        this.onChatOpenChange.next(this.isChatOpen);
        this.selectedChats(id);

    }

    /**

     /**
     * Update User Profile
     *
     * @param updatedClient
     */
    updateUserProfile(updatedClient) {
        updatedClient.lastUpdate = new Date().toISOString().slice(0, 19).replace('T', ' ');
        return this._httpClient.put<any>(`${environment.apiUrl}/updateuser`, updatedClient).subscribe((res) => {
            if (('currentUser')) {
                localStorage.removeItem('currentUser');
            }
            localStorage.setItem('currentUser', JSON.stringify(updatedClient));
            this._authenticationService.currentUserSubject.next(updatedClient);
        })
    }


    resetUnseenMsgInSingleConversation(contactId) {
        return new Promise<void>((resolve, reject) => {
            this._httpClient.put((`${environment.apiUrl}/contacts/unseenmsg/` + contactId), '').subscribe(() => {
                this._messagesService.getUnseenMsg();
                resolve();
            }, reject);
        });

    }

    /**
     * change message data structure
     * @param messages
     * @param contactPersonId
     * @private
     */
    private changeDataStructure(messages, contactPersonId) {
        let msgArr = [];
        let new_obj;
        messages.forEach((msgObj, index) => {
            if ((msgObj.sender === contactPersonId && msgObj.receiver === this.currentUser.id) || msgObj.receiver === contactPersonId && msgObj.sender === this.currentUser.id) {
                msgArr.push(
                    {
                        message: msgObj.message,
                        time: msgObj.issuedAt,
                        senderId: msgObj.sender === this.currentUser?.id ? this.currentUser?.id : msgObj.sender
                    }
                )
            }
        })

        new_obj = {
            id: messages[0]?.id ? messages[0]?.id : 1,
            userId: contactPersonId,
            unseenMsgs: 1,
            chat: msgArr
        }
        return new_obj;

    }


    clearChat(email) {
        return new Promise<void>((resolve, reject) => {
            this._httpClient.delete(`${environment.apiUrl}/messages/` + email).subscribe((response: any) => {
                if (response.error) {
                    this._alertService.onErrorMessage(
                        this._translateService.instant('authentication.general.onError.msg'), this._translateService.instant('authentication.chat.clear.title'));
                } else {
                    setTimeout(() => {
                        this._alertService.onSuccessMessage(
                            this._translateService.instant('authentication.chat.clear.onSuccessMsg_msg'),
                            this._translateService.instant('authentication.chat.clear.title'));
                    }, 1500);
                    //clear from localstorge if needed

                    this._router.navigate(['/account/messages']);
                }
                resolve();
            }, reject);
        });
    }

    /**
     * report user from chat section
     * @param data
     */
    onReportUser(data) {
        return this._httpClient.post(`${environment.apiUrl}/user-reports/create-new-report`, {...data});
    }

    /**
     * Get chat History between loggedIn person and selected contact person
     * @param contactID
     */
    // getMessages(contactID) {
    //     return this._httpClient.get<any[]>(`${environment.apiUrl}/contact-messages/${contactID}`);
    // }

    // Send a message to the specified receiver
    sendMessage(senderId: any, receiverId: any, message: string) {
        this.socket.emit('sendMessage', {senderId, receiverId, message});
    }

    // Listen for incoming messages in real-time
    receiveMessage(): Observable<any> {
        return new Observable(observer => {
            this.socket.on('receiveMessage', (data: any) => {
                observer.next(data);
            });

            // Cleanup function for unsubscription
            return () => {
                this.socket.off('receiveMessage');
            };
        });
    }

    getMessages(user2: any): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.apiUrl}/messages/${user2}`);
    }

}
