export const availablePages = [
    {
        id: 1,
        enTitle: 'home',
        deTitle: 'start Seite',
        icon: 'home',
        link: '/'
    },

    {
        id: 2,
        enTitle: 'chat',
        deTitle: 'Chat',
        icon: 'message-square',
        link: 'account/messages'
    },
    {
        id: 3,
        enTitle: 'notifications',
        deTitle: 'benachrichtigung',
        icon: 'bell',
        link: "account/notifications"
    },
    {
        id: 4,
        enTitle: 'account',
        deTitle: 'konto',
        icon: 'user',
        link: "account/account-settings"
    },
    {
        id: 5,
        enTitle: 'list item',
        deTitle: 'liste artikel',
        icon: 'folder-plus',
        link: "account/list-an-item"
    },
    {
        id: 6,
        enTitle: 'rentals',
        deTitle: 'mieten',
        icon: 'menu',
        link: "account/rentals"
    },
    {
        id: 7,
        enTitle: 'request',
        deTitle: 'anfragen',
        icon: 'git-pull-request',
        link: "account/requests"
    },
    {
        id: 8,
        enTitle: 'wishlist',
        deTitle: 'favorite list',
        icon: 'home',
        link: "account/wishlist"
    },
    {
        id: 9,
        enTitle: 'store',
        deTitle: 'laden',
        icon: 'layers',
        link: "account/my-store"
    },
    {
        id: 10,
        enTitle: 'profile',
        deTitle: 'profile',
        icon: 'home',
        link: "account/profile"
    },
    {
        id: 11,
        enTitle: 'dashboard',
        deTitle: 'dashboard',
        icon: 'home',
        link: "account/dashboard"
    },
    {
        id: 12,
        enTitle: 'delete account',
        deTitle: 'konto loeschen',
        icon: 'home',
        link: "account/account-settings"

    },
    {
        id: 13,
        enTitle: 'bank account',
        deTitle: 'iban',
        icon: 'home',
        link: "account/account-settings"

    },
    {
        id: 14,
        enTitle: 'change password',
        deTitle: 'passwort aendern',
        icon: 'home',
        link: "account/account-settings"
    },
    {
        id: 15,
        enTitle: 'items',
        deTitle: 'items',
        icon: 'list',
        link: "items",

    },
    {
        id: 16,
        enTitle: 'blog',
        deTitle: 'blog',
        icon: 'file-text',
        link: "blog",

    },
    {
        id: 17,
        enTitle: 'faq',
        deTitle: 'faq',
        icon: 'help-circle',
        link: "faq",

    },
    {
        id: 18,
        enTitle: 'terms of use',
        deTitle: 'nutzungsbedingungen',
        icon: 'home',
        link: "terms-of-use",

    },
    {
        id: 19,
        enTitle: 'privacy policy',
        deTitle: 'datenschutz',
        icon: 'home',
        link: "privacy-policy",

    }
]
