<!-- NAVBAR -->
<ng-container *ngTemplateOutlet="isLoggedIn? navbar:staticNavbar"></ng-container>
<!--/ NAVBAR -->

<!-- MENU -->
<ng-container *ngTemplateOutlet="menu"></ng-container>
<!--/ MENU -->

<!-- APP-CONTENT LOGGEDIN USER-->
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow" *ngIf="!coreConfig.layout.navbar.hidden"></div>
    <!-- CONTENT -->
    <content></content>
    <!--/ CONTENT -->
</div>
<!--/ APP-CONTENT -->

<!-- FOOTER -->
<ng-container *ngTemplateOutlet="isLoggedIn? footer : staticFooter"></ng-container>
<!--/ FOOTER -->

<!-- PARTIALS ---------------------------------------------------------------------------------------- -->

<!-- NAVBAR -->
<ng-template #navbar>
    <app-navbar
            *ngIf="!coreConfig.layout.navbar.hidden"
            [ngClass]="[
      coreConfig.layout.navbar.customBackgroundColor === true
        ? coreConfig.layout.navbar.background +
          ' ' +
          coreConfig.layout.navbar.type +
          ' ' +
          coreConfig.layout.navbar.backgroundColor
        : coreConfig.layout.navbar.background + ' ' + coreConfig.layout.navbar.type,
      coreConfig.layout.navbar.type === 'floating-nav' ? 'container-xxl' : ''
    ]"
            class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow"
    >
    </app-navbar>
</ng-template>
<!--/ NAVBAR -->

<ng-template #staticNavbar>
    <app-nav-header-static *ngIf="!coreConfig.layout.navbar.hidden"></app-nav-header-static>
</ng-template>

<!-- MENU -->
<ng-template #menu>
    <core-sidebar
            name="menu"
            [collapsed]="coreConfig.layout.menu.collapsed"
            collapsibleSidebar="bs-gt-xl"
            *ngIf="!coreConfig.layout.menu.hidden && isLoggedIn"
            class="main-menu menu-fixed menu-accordio menu-shadow"
            overlayClass="sidenav-overlay"
            [ngClass]="[
      coreConfig.layout.skin === 'semi-dark' || coreConfig.layout.skin === 'dark' ? 'menu-dark' : 'menu-light',
      coreConfig.layout.menu.collapsed ? '' : 'expanded'
    ]"
    >
        <app-menu menuType="vertical-menu" class="vertical-menu"></app-menu>
        <div>as</div>
    </core-sidebar>
</ng-template>
<!--/ MENU -->

<!-- FOOTER -->
<ng-template #footer>
    <footer
            *ngIf="!coreConfig.layout.footer.hidden"
            class="footer"
            [ngClass]="
      coreConfig.layout.footer.customBackgroundColor === true
        ? coreConfig.layout.footer.background +
          ' ' +
          coreConfig.layout.footer.type +
          ' ' +
          coreConfig.layout.footer.backgroundColor
        : coreConfig.layout.footer.background + ' ' + coreConfig.layout.footer.type
    "
    ></footer>
</ng-template>
<!-- / FOOTER -->

<ng-template #staticFooter>
    <app-footer-static *ngIf="!coreConfig.layout.footer.hidden "></app-footer-static>
</ng-template>
