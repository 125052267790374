import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-colshab-alerts',
    templateUrl: './colshab-alerts.component.html',
    styleUrls: ['./colshab-alerts.component.scss']
})
export class ColshabAlertsComponent implements OnInit {

    @Input() text: string;
    @Input() type: string;
    alertClose = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
