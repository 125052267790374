 export const locale = {
    lang: 'en',
    data: {
            welcomeTemplate:{
                title:"welcome on board!",
                text:'Thanks for creating an account with Colshab! We\'re so happy to have you on board.'
            },
            sentRequestTemplate:{
                title : 'Request sent',
               content : 'You sent a request for {{lender}} !'
            },
            newRequestTemplate:{
                title:"New Request",
                text:"You got new request from {{sender}}"
            },
            requestAcceptedTemplate:{
                title:"Request approved ",
                text:"Congratulation! Request has been approved. New chat has been opened with {{user}}"
            },
            requestRejectedTemplate:{
                title:"Request rejected",
                text:"Unfortunately! Request has been rejected by {{user}}"
            },
            requestCanceledTemplate:{
                title:"Request cancelled",
                text:"Unfortunately! Request has been cancelled by {{user}}"
            }
    }
};
