import {Component, Input, OnInit} from '@angular/core';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {locale as english} from "../../apps/requests-v2/i18n/en";
import {locale as german} from "../../apps/requests-v2/i18n/de";

@Component({
  selector: 'app-order-status-label2',
  templateUrl: './order-status-label2.component.html',
  styleUrls: ['./order-status-label2.component.scss']
})
export class OrderStatusLabel2Component implements OnInit {

  @Input() status;
  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(english, german);
  }

  ngOnInit(): void {
  }

}
