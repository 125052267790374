export const locale = {
    lang: 'en',
    data: {
        admin:{
            preview:"Preview"
        },
        general:{
            home:"Home",
            included:"included",
            items:"Items",
            item_list:"Item List",
            item_status:{
                available: "Available",
                rented: "currently rented"
            }

        },
        related_items:{
            title:"Related items",
            text:"People also search for this items"
        },
        contentHeader:{
            headerTitle: 'Item Details',
            link_1: 'Home',
            link_2: 'items',
            link_3: 'Details',

        },
        categories: {
            cameras: "Cameras & Photos",
            fashion: "Fashion",
            family: "Family & Kids",
            tools: "Tools",
            home: "Home",
            emobility: "E-Mobility",
            drones: "Drones",
            electronics: "Electronics",
            sport: "Sport",
            events: "Events",
            entertainment: "Entertainment",
            transport: "Transport",
            travel: "Travel",
            other: "Other"
        },
        checkout: {
            price_details: {
                rental_period: 'Rental period',
                total_days: 'Total days',
                subtotal: 'Subtotal',
                service_fees: 'Service fees',
                total_amount: 'Total amount',
                step_1: 'Step 1',
                step_2: 'Step 2',
                price_details:"Price details",
                coupon:"Coupon code"
            },
            component: {
                checkout: 'Checkout',
                metadata:{
                    description: ' Get the best deals on our online platform. Shop now for a wide variety of products at affordable prices.',
                    keywords: 'online shopping, checkout, payment, products, deals, discounts, affordable prices'
                },
                item_and_details: 'Item and details',
                response: 'Response',
                wait_response: 'Wait for a response'

            },
            buttons: {
                add_to_wishlist: 'Add to wishlist',
                apply: 'Apply',
                loading: 'Loading...',
                place_order:"Place order"
            }
        },
        details: {
            title:"{{itemName}}",
            metadata:{
                description: 'Learn more about {{itemName}} and purchase it on our website. See product details, specifications, and reviews.',
                keywords: '{{itemName}}, product details, product specifications, product reviews, ecommerce, online shopping,online-renting'
            },
            date:"select your desired dates",
            isItemCreator:'oops, you can not send a request to your self',
            send_request:"Send request",
            wishlist:"Wishlist",
            day: 'day',
            week: 'week',
            month: 'month',
            discount:"off will be applied"
        },
        item: {
            from: 'from ',
            by: 'By ',
            free_shipping: 'Free Shipping',
            wishlist: 'Wishlist',
            buttons: {
                delete: 'Delete',
                rent: 'Rent',
                edit: 'Edit',
                remove: 'Remove',
            }
        },
        shop: {
            sidebar: {
                filters: 'Filters',
                multi_range: 'Multi Range',
                price_range:'Price Range',
                categories:'Categories',
                ratings:'Ratings',
                up: ' & Up ',
                clear_filter:'Clear All Filters'
            },
            shop_component: {
                title:"Ecommerce Shop",
                metadata:{
                    description: 'Find Anything, Anytime and Anything You Need to Rent Low prices for renting',
                    keywords: 'Explore, Article, Items, To rent, Low prices, Rent items online, Rent anything you need, Share items easily, Borrow items nearby, Affordable rentals, Sustainable sharing, Rent anything you need, Find what you\'re looking for, Share economy made easy, Save money by renting, Sustainable consumption, Your sharing marketplace, Rent and lend with ease, Discover new possibilities, Support local sharing, Sustainable lifestyle made easy, Try before you buy'
                },
                search_item:'Search Item...',
                sorry:'Sorry, we could not find any result!',
                reset_filter:'Reset Filter!',
                result_found:'Results Found',
                featured:'Featured',
                lowest: 'Lowest',
                highest:'Highest',
                item: 'item'
            }
        },
        wishlist: {
            title:"Wishlist",
            metadata:{
                description: 'Create and manage your wishlist of desired items to rent or lend on our platform',
                keywords: 'Wishlist, Desired items, Item wishlist, Rental wishlist, Lending wishlist, Wishlist management, Wishlist customization, Wishlist system, Wishlist feature, Wishlist dashboard'
            },
            no_results: 'No bookmarked items found!',
            buttons: {
                explore_items: 'Explore items'
            }
        },
      alerts:{
          onItemDelete:{
              title: 'Are you sure, you want to delete {{item}} ?' ,
              text: "You won't be able to revert this!",
              confirmButtonText: 'Yes, delete it!',
          },
          onError:{
              title: 'Error found',
              text: 'An error has occurred , please try again letter!)',
          },
          afterDelete:{
              title: 'Deleted!',
              text1: 'Item "' ,
              text2:  '" has been deleted.',
          }
      }
    }

};
