export const locale = {
    lang: 'en',
    data: {
        title:"Requests",
        search:"Search...",
        metadata:{
            description: 'Send and receive items requests quickly and efficiently',
            keywords: 'Item requests, Sharing platform, Send request, Accept request, requests list, Item sharing, Sharing community, Request system'
        },
        list:{
            item:"item",
            days:'days',
            client:"Client",
            period:'start - end',
            issued_date:"issued date",
            total:"Total",
            status:"Status",
            actions:"Actions"
        },
        order_status: {
            pending: 'Wait for approval',
            accepted: 'Request approved',
            completed: 'Successfully returned',
            rejected: 'Request rejected',
            cancelled: 'Renter cancelled the request',
            rented: 'Currently rented'
        },
        buttons: {
            explore_items: 'Explore Items',
            search:'Search...',
            select_status:'Select Status',
            reject:'Reject request',
            accept:'Accept Request',
            send:'Send message',
            invoice:'Preview Invoice'
        },
        order_status_options:{
            all: 'All',
            accepted:'Accepted',
            cancelled:'Cancelled',
            completed:'Completed',
            pending:'Pending',
            rented:'Rented',
            rejected:'Rejected'
        },
        typescripts_files: {
            onReject:{
                title:'Are you sure to reject the request?',
                text:'You won\'t be able to revert this!',
                confirmButtonText:'Yes, reject it!',
                success_msg:{
                    title:'Rejected!',
                    text:'Your Request has been rejected.'
                },
                err_msg:{
                    title:'Error!',
                    text:'An error has occurred,try again letter'
                }

            }
        }
    }
};

