export const locale = {
    lang: 'en',
    data: {
        footer:{
            news_letter_text:"Join Our Mailing List To Receive The News & Latest Trends",
            subscribe:"Subscribe Now",
            loading:"Loading...",
            pages:"pages",
            u_links:"Useful Links",
            social_Media:"Social Media",
            links:{
                about:"About Us",
                contact:"Contact",
                help:"Help & FAQs",
                how_it_works:"How It Works",
                blog:"Blog",
                list_item:"List new item",
                explore:"Explore items",
                business:"Business",
                privacy:"Privacy Policy",
                terms:"Terms & Conditions",
            }
        },
        dialog:{
            title:"Newsletter",
            text:"Thank you for subscribing to our newsletter! We're excited to have you as part of our community and look forward to keeping you updated on our latest updates, news, and promotions."
        },
        general:{
            error_response:{
                title: 'Somthing went wrong!',
                text:'An error has occurred , please try again letter!',
            },
        }
    }
};
