import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-raiting-section',
    templateUrl: './raiting-section.component.html',
    styleUrls: ['./raiting-section.component.scss']
})
export class RaitingSectionComponent implements OnInit {
    @Input() rateValue: number;

    constructor() {
    }

    ngOnInit(): void {
    }

}
