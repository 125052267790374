<li class="nav-item nav-search">
    <!-- Search icon -->
    <a class="nav-link nav-link-search" (click)="toggleSearch()"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
    ></a>

    <!-- Search -->
    <div class="search-input" [class.open]="openSearchRef === true">
        <!-- Search Input -->
        <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
        <input
                class="form-control input"
                type="text"
                placeholder="{{'navbar.search.placeHolder'|translate}}"
                tabindex="-1"
                data-search="search"
                (keyup)="searchUpdate($event)"
                [(ngModel)]="searchText"
                #openSearch
        />


        <button class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

        <!-- Search List -->
        <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
            <!--             Pages -->
            <li class="d-flex align-items-center">
                <a href="javascript:void(0)"><h6
                        class="section-label mt-75 mb-0">{{'navbar.search.pages'|translate}}</h6></a>
            </li>
            <ng-container
                    *ngIf="(pages | filter: searchText:currentLanguage==='en'?'enTitle':'deTitle' ).length; else noResultsPages">
                <li
                        class="auto-suggestion"
                        *ngFor="let page of pages | filter: searchText:getSearchKey() | slice: 0:4; let i = index"
                        [ngClass]="{ current_item: i === activeIndex }"
                        [class.current_item]="page.hover"
                        (mouseover)="page.hover = true"
                        (mouseout)="page.hover = false">
                    <a
                            class="d-flex align-items-center justify-content-between w-100"
                            (click)="toggleSearch()"
                            (keyup.enter)="navigate(page.link)"
                            [routerLink]="page.link">

                        <div class="d-flex justify-content-start align-items-center">
                            <i data-feather="info"
                               class="mr-75"></i><span>{{ currentLanguage === 'en' ? page.enTitle : page.deTitle }}</span>
                        </div>
                    </a
                    >
                </li>
            </ng-container>

            <!-- No results found -->
            <ng-template #noResultsPages>
                <li class="auto-suggestion justify-content-between">
                    <a class="d-flex align-items-center justify-content-between w-100 py-50">
                        <div class="d-flex justify-content-start">
                            <i data-feather="alert-circle" class="mr-25"></i>
                            <span>{{'navbar.menu.no_results'|translate}}</span>
                        </div>
                    </a
                    >
                </li>
            </ng-template>
            <!--/ Pages -->

            <!--             Files-->
            <li class="d-flex align-items-center">
                <a href="javascript:void(0)"><h6
                        class="section-label mt-75 mb-0">{{'navbar.search.items'|translate}}</h6>
                </a>
            </li>
            <ng-container *ngIf="(items | filter: searchText:'title').length; else noResultsItems">
                <li
                        class="auto-suggestion"
                        *ngFor="let item of items | filter: searchText:'title' | slice: 0:4; let e = index"
                        [ngClass]="{ current_item: e === activeIndex }"
                        [class.current_item]="item.hover"
                        (mouseover)="item.hover = true"
                        (mouseout)="item.hover = false"
                >
                    <a class="d-flex align-items-center justify-content-between w-100"
                       (click)="toggleSearch()"
                       [routerLink]="['/items/details', item.id, item.title]"
                    >
                        <div class="d-flex">
                            <div class="mr-75"><img src="{{itemsBackendPath}}{{ item.image1}}" alt="{{item.title}}"
                                                    width="32"
                                                    height="32"/>
                            </div>
                            <div class="search-data">
                                <p class="search-data-title mb-0">{{ item.title }}</p>
                                <small class="text-muted">{{ item.created_by_name }}</small>
                            </div>
                        </div>
                        <small class="search-data-size mr-50 text-muted">{{ item.issued_at |date }}</small></a
                    >
                </li>
            </ng-container>
            <!-- No results found -->
            <ng-template #noResultsItems>
                <li class="auto-suggestion justify-content-between">
                    <a class="d-flex align-items-center justify-content-between w-100 py-50">
                        <div class="d-flex justify-content-start">
                            <i data-feather="alert-circle" class="mr-25"></i>
                            <span>{{'navbar.menu.no_results'|translate}}</span>
                        </div>
                    </a
                    >
                </li>
            </ng-template>
            <!--/ Files -->

            <!-- Members -->
            <li class="d-flex align-items-center">
                <a href="javascript:void(0)"><h6
                        class="section-label mt-75 mb-0">{{'navbar.search.members'|translate}}</h6>
                </a>
            </li>
            <ng-container *ngIf="(contacts | filter: searchText:'fullName').length; else noResultsMembers">
                <li class="auto-suggestion"
                    *ngFor="let contact of contacts | filter: searchText:'fullName' | slice: 0:4">
                    <a class="d-flex align-items-center justify-content-between py-50 w-100"
                       (click)="toggleSearch()"
                       [routerLink]="['account/profile/', contact.fullName,contact.id]"
                    >
                        <div class="d-flex align-items-center">
                            <div class="avatar mr-75"><img *ngIf="contact.avatar"
                                                           src="{{backendPath}}{{ contact.avatar }}"
                                                           alt="{{contact.fullName}}" height="32"
                                                           width="32"/>
                                <span class="avatar-content"
                                      *ngIf="!contact.avatar">{{contact.fullName | titlecase | initials }}</span></div>
                            <div class="search-data">
                                <p class="search-data-title mb-0">{{ contact.fullName }}</p>
                                <small class="text-muted">{{ contact.raiting }}</small>
                            </div>
                        </div>
                        <small class="search-data-size mr-50 text-muted">{{ contact.createdAt | date }}</small></a
                    >
                </li>
            </ng-container>
            <!-- No results found -->
            <ng-template #noResultsMembers>
                <li class="auto-suggestion justify-content-between">
                    <a class="d-flex align-items-center justify-content-between w-100 py-50">
                        <div class="d-flex justify-content-start">
                            <i data-feather="alert-circle" class="mr-25"></i>
                            <span>{{'navbar.menu.no_results'|translate}}</span>
                        </div>
                    </a
                    >
                </li>
            </ng-template>
            <!--/ Members -->
        </ul>
        <!--/ Search List -->
    </div>
</li>
