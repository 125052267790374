import {Component, Input, OnInit} from '@angular/core';
import {locale as german} from 'app/main/components/i18n/de';
import {locale as english} from 'app/main/components/i18n/en';
import {TranslateService} from "@ngx-translate/core";
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-success-response-no-popup',
    templateUrl: './success-response-no-popup.component.html',
    styleUrls: ['./success-response-no-popup.component.scss']
})
export class SuccessResponseNoPopupComponent implements OnInit {
    @Input() text;
    title = '';

    constructor(private _coreTranslationService: CoreTranslationService, private _router: Router, private _translateService: TranslateService) {
        this._coreTranslationService.translate(english, german);
        this.title = this._translateService.instant('success_response_no_popup.title');
    }

    ngOnInit(): void {

    }

}
