import {Injectable} from '@angular/core';

import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from "rxjs";

export interface Locale {
    lang: string;
    data: Object;
}

@Injectable({
    providedIn: 'root'
})
export class CoreTranslationService {
    public onSelectedLanguageChange: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {TranslateService} _translateService
     */
    constructor(private _translateService: TranslateService) {
        this.onSelectedLanguageChange = new BehaviorSubject<string>(this._translateService.currentLang);
        this.onLangChange();
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Translate
     *
     * @param {Locale} args
     */
    translate(...args: Locale[]): void {
        const locales = [...args];
        locales.forEach(locale => {
            // use setTranslation() with the third argument value as true to append translations instead of replacing them
            this._translateService.setTranslation(locale.lang, locale.data, true);
        });
    }

    /**
     * order status list in 'en' and 'de'
     */
    getOrderStatusList() {
        return [
            {name: this._translateService.instant('order_status_options.all'), value: 'All'},
            {name: this._translateService.instant('order_status_options.accepted'), value: 'Accepted'},
            {name: this._translateService.instant('order_status_options.cancelled'), value: 'Cancelled'},
            {name: this._translateService.instant('order_status_options.completed'), value: 'Completed'},
            {name: this._translateService.instant('order_status_options.pending'), value: 'Pending'},
            {name: this._translateService.instant('order_status_options.rented'), value: 'Rented'},
            {name: this._translateService.instant('order_status_options.rejected'), value: 'Rejected'}
        ];
    }

    getOrderDefaultStatusLabel() {
        return this._translateService.instant('order_status_options.all');
    }

    onLangChange() {
        this._translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.onSelectedLanguageChange.next(event.lang);
        });
    }

    /**
     * get Current Language
     */
    getCurrentLanguage(): string {
        return this._translateService.currentLang;
    }

    /**
     * Gte Language change
     */
    onLanguageChange(): Observable<string> {
        return this.onSelectedLanguageChange.asObservable();
    }
}
