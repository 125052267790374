export const locale = {
    lang: 'de',
    data: {
        header:{
            read_notification:" Alle Benachrichtigungen lesen",
            notifications:"Benachrichtigungen",
            new:"Neue"
        },
        navbar: {
            menu: {
                account: "Konto",
                help: "Hilfe & Support",
                logout: "Abmelden",
                no_results: "Keine Ergebnisse gefunden"
            },
            search:{
                placeHolder:"Erkunde Colshab...",
                pages:"Seiten",
                items:"Artikel",
                members:"Mitglieder",
            },
        },

        notifications: {
            welcomeTemplate: {
                title: "Herzlich Willkommen!",
                text: "Danke, dass Du ein Konto bei Colshab erstellt hast! Wir freuen uns sehr, dass Du an Bord bist."
            },
            sentRequestTemplate: {
                title: "Anfrage gesendet",
                content: "Du hast eine Anfrage an {{lender}} gesendet!"
            },
            newRequestTemplate: {
                title: "Neue Anfrage",
                text: "Du hast eine neue Anfrage von {{sender}} erhalten."
            },
            requestAcceptedTemplate: {
                title: "Anfrage genehmigt",
                text: "Herzlichen Glückwunsch! Die Anfrage wurde genehmigt. Es wurde ein neuer Chat mit {{user}} eröffnet."
            },
            requestRejectedTemplate: {
                title: "Anfrage abgelehnt",
                text: "Leider wurde Deine Anfrage von {{user}} abgelehnt."
            },
            requestCanceledTemplate: {
                title: "Anfrage storniert",
                text: "Leider wurde Deine Anfrage von {{user}} storniert."
            }
        }

    }
};
