import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "../service";

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
 constructor(private _router: Router, private _authenticationService: AuthenticationService) {
 }

    /**
     * NStatic pages guard==> only when user not logged in
     * @param route
     * @param state
     */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser){
      this._router.navigate(['items'])
      return false
    }
    else{

      return true
    }
  }
  
}
