export const locale = {
    lang: 'de',
    data: {
        features: {
            title1: "Lokal teilen",
            text1: "Greife auf eine Vielzahl von Gegenständen zu, um sie mit anderen Mitgliedern deiner lokalen Gemeinschaft auszuleihen oder zu verleihen.",
            title2: "Gemeinsam Abfall reduzieren",
            text2: "Spare Geld und reduziere Verschwendung, indem du deine selten genutzten Gegenstände mit anderen teilst, die sie benötigen.",
            title3: "Verbinden und Teilen",
            text3: "Verbinde dich mit Gleichgesinnten, um deine Hobbys, Ausrüstung und Erfahrungen zu teilen."
        },
        contact_us:{

        },
        success_response:{
            title:'Danke schön',

        },
        error_response:{
            title: 'Etwas ist schief gelaufen!',
            text: "Du wirst nicht das zurücksetzen können!",
        },
        success_response_no_popup:{
            title:'Success',
        },
        error_response_no_popup:{
            title: 'Etwas ist schief gelaufen!',
        },
        buttons: {
            confirmButtonText: 'Ok',
            back: 'Zurück zur Startseite',
            explore_items: 'Artikel Entdecken',
            try_again: 'Erneut versuchen'
        }
    }
};
