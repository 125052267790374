export const locale = {
    lang: 'de',
    data: {
        labels: {
            pictures: ' Bilder',
            picture_sub_title: 'Bilder hochladen oder per Drag & Drop hinzufügen',
            allowed_images: '*Erlaubte JPG- oder PNG-Bilder',
            allowed_number_images: '*Anzahl der Bilder muss zwischen 1 und 4 sein',
        },
        onWarningMessage: {
            title: 'Maximal 4 Bilder!',
            text: 'Es sind maximal 4 Bilder erlaubt!'
        },
        onErrorMessage: {
            title: 'Bilder hochladen',
            text: 'Es sind nur JPG oder PNG Bilder erlaubt!'
        }
    }
};
