import {CoreMenu} from '@core/types';

//? DOC: http://localhost:7777/demo/colshab-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
    // Dashboard
    {
        // If role is not assigned will be display to all
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD.DASHBOARD',
        type: 'item',
        icon: 'home',
        url: 'account/dashboard'
    },
    {
        id: 'account-settings',
        title: 'Account',
        translate: 'MENU.PAGES.ACCOUNTSETTINGS',
        type: 'item',
        icon: 'user',
        url: 'account/account-settings'
    },
    {
        id: 'chat',
        title: 'Chat',
        translate: 'MENU.APPS.CHAT',
        type: 'item',
        icon: 'message-square',
        url: 'account/messages'
    },
    {
        id: 'items',
        title: 'Explore items',
        translate: 'MENU.APPS.ECOMMERCE.SHOP',
        type: 'item',
        icon: 'list',
        url: 'items'
    },
    {
        id: 'lisAnItem',
        title: 'List an item',
        translate:'MENU.APPS.ECOMMERCE.LISTANITEM',
        type: 'item',
        icon: 'folder-plus',
        url: 'account/list-an-item'
    },
    {
        id: 'requests',
        title: 'Requests',
        translate: 'MENU.APPS.ECOMMERCE.REQUEST',
        type: 'item',
        icon: 'git-pull-request',
        url: 'account/requests'
    },
    {
        id: 'rentals',
        title: 'Rentals',
        translate: 'MENU.APPS.ECOMMERCE.RENTALS',
        type: 'item',
        icon: 'menu',
        // url: 'apps/invoice/list'
        url:'account/rentals'

    },
    {
        id: 'store',
        title: 'My Store',
        translate: 'MENU.APPS.ECOMMERCE.STORE',
        type: 'item',
        icon: 'layers',
        url:'account/my-store'
    },
    {
        id: 'blog',
        title: 'Blog',
        translate: 'MENU.PAGES.BLOG.COLLAPSIBLE',
        type: 'item',
        url: 'blog',
        icon: 'file-text',
    },
    {
        id: 'faq',
        title: 'FAQ',
        translate: 'MENU.PAGES.FAQ',
        type: 'item',
        icon: 'help-circle',
        url: 'faq'
    },


    //start of admin and super admin components
    // {
    //     id: 'admin',
    //     title: 'Admin',
    //     translate: 'MENU.admin_menu.blog.title',
    //     type: 'collapsible',
    //     icon: 'circle',
    //     isAdmin: true,
    //     children: [
    //         {
    //             id: 'createNewBlog',
    //             title: 'createNewBlog',
    //             translate: 'MENU.admin_menu.blog.create',
    //             type: 'item',
    //             url: 'blog/create-new-post'
    //         }
    //     ]
    // },
];
