<div id="success_msg">

        <div class="after_card">
            <i class="checkmark">✓</i>
        </div>
        <div class="pt-2">
            <h1>{{title}}</h1>
            <p>{{text}}</p>
        </div>

    <div class="col-12 pt-2">
        <button routerLink="/" rippleEffect class="btn btn-primary mr-1"> <span [data-feather]="'home'" [class]="'mr-25'"></span> {{'buttons.back'|translate}}</button>
        <button routerLink="/items" rippleEffect class="btn btn-outline-secondary"><span [data-feather]="'arrow-right'" [class]="'mr-25'"></span>{{'buttons.explore_items'|translate}}</button>
    </div>
</div>

