export const locale = {
    lang: 'en',
    data: {
        authentication: {
            register: {
                onSuccessMsg: 'You have successfully registered as an {{role}} in Colshab. Now you can start to journey. Enjoy!',
                title: 'Welcome, {{name}}!',
            },
            login: {
                onSuccessMsg: 'You have successfully logged in as an {{role}} in Colshab. Now you can start to explore. Enjoy!',
                title: 'Welcome back, {{name}}!',
            },
            updateResetedPassword: {
                onSuccessMsg_msg: 'Password has been updated ,please login again with your new password!',
                title: 'Password Reset'
            },
            logout: {
                title: 'Logout',
                msg:"Auf wiedersehen"
            },
            profile_update: {
                title: 'Profile update!',
                onSuccessMsg_msg: 'Profile was successfully updated! ',
            },
            resetProfilePicture: {
                onSuccessMsg_msg: 'profile picture was successfully rested!',
                title: 'Reset profile Image!'
            },
            updateUserPassword: {
                onSuccessMsg_msg: 'Password has been updated ,please login again with your new password! ',
                title: 'Password update',
                errMsg: 'Your old password does not match!'
            },
            deleteUser: {
                onSuccessMsg_msg: 'Unfortunately, you have successfully deleted your account ',
                title: 'Delete account',
            },
            chat: {
                clear: {
                    title: 'Clear chat',
                    onSuccessMsg_msg: 'Chat has been successfully deleted!'
                }
            },
            invoice_list: {
                title: 'Status update',
                onSuccessMsg_msg: 'Status has been updated to  '
            },
            requests: {
                updateItemStatus: {
                    title: 'Status update',
                    onSuccessMsg_msg: 'Status has been updated to  '
                },
                acceptRequest: {
                    title: 'Status update',
                    onSuccessMsg_msg: 'Status has been successfully updated to accepted',
                    renterToLendetMsg: {
                        msg1: 'Hey ',
                        msg2: ' , I would like to rent the item '
                    },
                    lenderToRenterMsg: {
                        msg1: 'Hey ',
                        msg2: ', it will be my pleasure to do business with you. Let\'s arrange that'
                    },
                }

            },
            ecommerce: {
                createNewItem: {
                    title: 'new item upload!',
                    onSuccessMsg_msg: 'Item was successfully created'
                },
                updateItem: {
                    title: 'update Item',
                    onSuccessMsg_msg: 'Item was successfully updated'
                },
            },

            iban:{
                title: "IBAN Submission",
                message: "Please submit your IBAN in your account settings page. This information is necessary to process your payments.",
            },
            general: {
                onError: {
                    title: 'Error',
                    msg: 'An error has occurred , please try again letter!',
                },
            }

        },
        general: {
            onError: {
                title: 'Error',
                msg: 'An error has occurred , please try again letter!',
            },
            onSocialErr:{
                title:"Social Login",
                msgFB:"Facebook login failed",
                msgGoogle:"Google login failed"
            }
        },
        onFB:{
            title:"Soziale Anmeldung",
            msgFB:"Facebook-Anmeldung fehlgeschlagen",
            msgGoogle:"Google-Anmeldung fehlgeschlagen"
        },
        onCancelOrder:{
            title:"Update Order",
            text:"Order was cancelled successfully"
        }

    }
};

