export const locale = {
    lang: 'de',
    data: {
        authentication: {
            register: {
                onSuccessMsg: 'Du hast dich erfolgreich als {{role}} bei Colshab registriert. Du kannst jetzt die Reise beginnen. Viel Spaß damit!',
                title: 'Willkommen, {{name}}!',
            },
            login: {
                onSuccessMsg: 'You have successfully logged in as an {{role}} in Colshab. Now you can start to explore. Enjoy!',
                title: 'Willkommen zurück, {{name}}!',
            },
            updateResetedPassword: {
                onSuccessMsg_msg: 'Das Passwort wurde aktualisiert. Bitte melde dich erneut mit deinem neuen Passwort an!',
                title: 'Passwort zurücksetzen'
            },
            logout: {
                title: 'Abmelden',
                msg: "See you next time"
            },
            profile_update: {
                title: 'Profil aktualisieren!',
                onSuccessMsg_msg: 'Profil wurde erfolgreich aktualisiert! ',
            },
            resetProfilePicture: {
                onSuccessMsg_msg: 'Profilbild wurde erfolgreich zurückgesetzt!',
                title: 'Profilbild zurücksetzen!'
            },
            updateUserPassword: {
                onSuccessMsg_msg: 'Das Passwort wurde aktualisiert. Bitte melde dich erneut mit deinem neuen Passwort an! ',
                title: 'Passwort aktualisieren',
                errMsg: 'Dein altes Passwort stimmt nicht überein!'
            },
            deleteUser: {
                onSuccessMsg_msg: 'Leider hast du dein Konto erfolgreich gelöscht ',
                title: 'Konto löschen',
            },
            chat: {
                clear: {
                    title: 'Chat löschen',
                    onSuccessMsg_msg: 'Chat wurde erfolgreich gelöscht!'
                }
            },
            invoice_list: {
                title: 'Status aktualisieren',
                onSuccessMsg_msg: 'Status wurde aktualisiert  '
            },
            requests: {
                updateItemStatus: {
                    title: 'Status aktualisieren',
                    onSuccessMsg_msg: 'Status wurde aktualisiert  '
                },
                acceptRequest: {
                    title: 'Status aktualisieren',
                    onSuccessMsg_msg: 'Status wurde erfolgreich auf "angenommen" aktualisiert',
                    renterToLendetMsg: {
                        msg1: 'Hey ',
                        msg2: ' , Ich möchte gerne den Artikel mieten '
                    },
                    lenderToRenterMsg: {
                        msg1: 'Moin ',
                        msg2: ', Es wird mir eine Freude sein, mit dir Business zu machen. Lass uns das arrangieren'
                    },
                }

            },
            ecommerce: {
                createNewItem: {
                    title: 'neues Artikel hochladen!',
                    onSuccessMsg_msg: 'Der Artikel wurde erfolgreich erstellt'
                },
                updateItem: {
                    title: 'Artikel aktualisieren',
                    onSuccessMsg_msg: 'Der Artikel wurde erfolgreich aktualisiert'
                },
            },

            iban: {
                title: "IBAN-Einreichung",
                message: "Bitte gib deine IBAN in deinen Kontoeinstellungen ein. Diese Informationen sind notwendig, um deine Zahlungen zu verarbeiten."
            },
            general: {
                onError: {
                    title: 'Fehler',
                    msg: 'Es ist ein Fehler aufgetreten, bitte versuche es später erneut!',
                }
            }

        },
        general: {
            onError: {
                title: 'Fehler',
                msg: 'Es ist ein Fehler aufgetreten, bitte versuche es später erneut!',
            },
            onSocialErr: {
                title: "Social Login",
                msgFB: "Facebook login failed",
                msgGoogle: "Google login failed"
            }
        },
        onFB: {
            title: "Soziale Anmeldung",
            msgFB: "Facebook-Anmeldung fehlgeschlagen",
            msgGoogle: "Google-Anmeldung fehlgeschlagen"
        },
        onCancelOrder: {
            title: "Bestellung aktualisieren",
            text: "Die Bestellung wurde erfolgreich storniert."
        }
    }
};
