import {Role} from './role';

export class User {
    id?: number | string;
    role?: Role;
    termsAccepted?: boolean;
    hasValidToken?: boolean;
    token?: string;
    fullName?: string;
    isCompany?: boolean;
    email?: string;
    password?: string;
    avatar?: string;
    lastVisit?: Date;
    lastUpdate: Date| string;
    city?: string;
    country?: string;
    contact?: string;
    bio?: string;
    status?: string;
    createdAt?: string;
    raiting?:number;
    social_id?:string;
    provider?:string;
}


