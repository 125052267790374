import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from 'app/auth/service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    /**
     *
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     */
    constructor(private _router: Router, private _authenticationService: AuthenticationService) {
    }

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this._authenticationService.isAuthenticated()) {

            // if (this._authenticationService.isUser()
            //     || this._authenticationService.isAdmin()
            //     || this._authenticationService.isSuperAdmin()
            //     || this._authenticationService.isCompany()) {
            //     return true;
            // } else {
            //     this._router.navigate(['/miscellaneous/not-authorized']);
            //     return false;
            // }
            return true;

        } else {
            this._router.navigate(['/authentication/login'], {queryParams: {returnUrl: state.url}});
            return false;
        }

    }
}
