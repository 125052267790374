<!-- Ecommerce Wishlist -->
<div class="content-wrapper container-xxl p-0">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
    <div class="content-body">
        <div *ngIf="!wishlist.length" class="checkout-items">
            <div class="col-12 col-md-12 col-lg-12">
                <div class="card card-congratulation-medal">
                    <div class="card-body">
                        <h5>{{'wishlist.no_results'|translate}}</h5>
                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <button type="button" routerLink="/items" class="btn btn-primary" rippleEffect><i
                                            data-feather="arrow-right"></i>{{'wishlist.buttons.explore_items'|translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="wishlist" class="grid-view wishlist-items">
            <ng-container *ngFor="let product of products">
                <app-ecommerce-item
                        class="card ecommerce-card"
                        [product]="product"
                        [isWishlistOpen]="true"
                        *ngIf="product.isInWishlist === true"
                ></app-ecommerce-item>
            </ng-container>
        </section>
    </div>
</div>
<!--/ Ecommerce Wishlist -->
