import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CoreConfigService} from "../../../../@core/services/config.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StaticService} from "../static.service";
import Swal from "sweetalert2";
import {locale as german} from 'app/main/static/footer-static/i18n/de';
import {locale as english} from 'app/main/static/footer-static/i18n/en';
import {CoreTranslationService} from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-footer-static',
    templateUrl: './footer-static.component.html',
    styleUrls: ['./footer-static.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterStaticComponent implements OnInit, OnDestroy {
    coreConfig: any;
    submitted = false;
    loading = false;

    public colshabFacebook = environment._facebookSocialAccount;
    public colshabInstagram = environment._instagramSocialAccount;
    public colshabTwitter = environment._twitterSocialAccount;

    currentYear: number = new Date().getFullYear();
    private _unsubscribeAll: Subject<any>;
    emailForm: FormGroup;

    constructor(private _translateService: TranslateService,private _coreTranslationService: CoreTranslationService, public _coreConfigService: CoreConfigService, private formBuilder: FormBuilder, private _appService: StaticService) {
        this._coreTranslationService.translate(english, german);
        this._unsubscribeAll = new Subject();
        this.emailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$")]],
        });
    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------


    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onSubscribe() {
        const emailForm = this.emailForm.value;
        this.submitted = true;
        if (this.emailForm.invalid) {
            return;
        }
        this.loading = true;
        this._appService.newsletterSubscribe(emailForm).subscribe((res) => {
            this.emailForm.reset();
            this.loading = false;
            if (!res.error) {
                setTimeout(() => {
                    Swal.fire(
                        this._translateService.instant('dialog.title')+ ' 👋',
                        this._translateService.instant('dialog.text'),
                        'success'
                    )
                }, 200);
            } else {
                setTimeout(() => {
                    Swal.fire(
                        this._translateService.instant('general.error_response.title'),
                        this._translateService.instant('general.error_response.text'),
                        'error'
                    )
                }, 200);
            }
        })
    }
}
