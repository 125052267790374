import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { CoreTranslationService } from '@core/services/translation.service';

import {locale as german} from 'app/main/static/nav-header-static/i18n/de';
import {locale as english} from 'app/main/static/nav-header-static/i18n/en';

@Component({
  selector: 'app-nav-header-static',
  templateUrl: './nav-header-static.component.html',
  styleUrls: ['./nav-header-static.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavHeaderStaticComponent implements OnInit {

  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(english, german);
  }

  ngOnInit(): void {
  }

}
