import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {
    @Input() title;
    @Input() sub_title;
  public searchText = '';

  constructor() { }

  ngOnInit(): void {
  }

}
