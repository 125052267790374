import {Component, Input, OnInit} from '@angular/core';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {locale as english} from "../i18n/en";
import {locale as german} from "../i18n/de";
import { Location } from '@angular/common';
@Component({
  selector: 'app-err-response-no-popup',
  templateUrl: './err-response-no-popup.component.html',
  styleUrls: ['./err-response-no-popup.component.scss']
})
export class ErrResponseNoPopupComponent implements OnInit {
  @Input() text;
  title = '';

  constructor(private location: Location,private _coreTranslationService: CoreTranslationService, private _router: Router, private _translateService: TranslateService) {
    this._coreTranslationService.translate(english, german);
    this.title = this._translateService.instant('error_response_no_popup.title');
  }

  ngOnInit(): void {

  }

  goBack(): void {
    this.location.back();
  }

}
