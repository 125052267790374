<li   #notificationDropdown="ngbDropdown" ngbDropdown class="nav-item dropdown-notification mr-25">
    <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up"> <span *ngIf="unSeenNotifications>0">+</span> {{
        unSeenNotifications
        }}</span></a
    >
    <ul
            ngbDropdownMenu
            aria-labelledby="navbarNotificationDropdown"
            class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    >
        <!-- Notifications header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">{{'header.notifications' |translate}}</h4>
                <div class="badge badge-pill badge-light-primary">
                    {{ unSeenNotifications }} {{'header.new' |translate}}
                </div>
            </div>
        </li>
        <!--/ Notifications header -->

        <!-- Notifications content -->
        <li  class="scrollable-container media-list" [perfectScrollbar]>
            <a *ngFor="let notification of notificationsArr" class="d-flex" [routerLink]="['/account/notifications',notification.id ]"
               (click)="openNotification(notification.id); $event.stopPropagation();notificationDropdown.close()">
                <div class="media d-flex align-items-start">
                    <div *ngIf="notification.code ===2 || notification.code ===1" class="media-left">
                        <div *ngIf="notification?.image"  class="avatar">
                            <img src="{{backendServerPath}}{{ notification.image }}" alt="{{ notification.title }}" width="32" height="32"/>
                        </div>

                        <div *ngIf="!notification.image" class="avatar bg-light-secondary">
                            <div class="avatar-content">{{notification.senderName | titlecase | initials }}</div>
                        </div>

                    </div>

                    <div *ngIf="notification.code !==2 && notification.code !==1 && notification.icon" class="media-left">
                        <div
                                class="avatar"
                                [ngClass]="{
                'bg-light-danger': notification.icon === 'x',
                'bg-light-success': notification.icon === 'check',
                'bg-light-warning': notification.icon === 'alert-triangle'
              }"
                        >
                            <div class="avatar-content"><i class="avatar-icon" [data-feather]="notification.icon"></i>
                            </div>
                        </div>
                    </div>

                    <div class="media-body">
                        <p class="media-heading" [innerHTML]="getNotificationTitle(notification)"></p>
                        <small class="notification-text">{{ getNotificationContent(notification)}}</small>
                        <small class="float-right mb-25 chat-time">{{ notification.lastupdate |date }}</small>
                    </div>
                </div>
            </a>
        </li>
        <!-- Notifications footer -->
        <li class="dropdown-menu-footer">
            <a class="btn btn-primary btn-block" (click)="onReadAllMessages();notificationDropdown.close()">{{'header.read_notification'|translate}}</a>
        </li>
        <!--/ Notifications footer -->
    </ul>
</li>
