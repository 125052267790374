<nav id="header" class="navbar navbar-expand-lg navbar-default fixed-top navbar-dark set-back-color ">
    <div class="container">
        <a routerLink="" class="logo">
            <img class="logo_header img-fluid" src="../../../../assets/images/logo/logo.png" alt="Colshab">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMain"
                aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse make-space" id="navbarMain">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a routerLink="/items" class="nav-link menu-content" href="">{{'explore' |translate}}</a>
                </li>
                <li class="nav-item"><a class="nav-link menu-content"
                                        routerLink="business">{{'business' |translate}}</a></li>
                <app-navbar-language class="menu-content"></app-navbar-language>
            </ul>

            <div class="gradient-button"><a router routerLink="/authentication/login"><i class="fa fa-sign-in-alt"></i>
                {{'register_Signin' |translate}} </a></div>

        </div>
    </div>
</nav>


