import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {AlertsService} from "../../../auth/service/alerts.service";
import {locale as english} from "../images-uploader-section/i18n/en";
import {locale as german} from "../images-uploader-section/i18n/de";
import {itemImagesPath} from "../../../../environments/environment";

@Component({
  selector: 'app-images-uploader-section',
  templateUrl: './images-uploader-section.component.html',
  styleUrls: ['./images-uploader-section.component.scss']
})
export class ImagesUploaderSectionComponent implements OnInit {
  @Output() uploadedFiles = new EventEmitter<any>();
  @Input() existingImages;
  files: File[] = [];

  onSelect(event: any) {
    if (event.addedFiles.length > 0) {
      this.files.push(...event.addedFiles);
      this.uploadedFiles.emit(this.files);
    }

    if (this.files.length > 4) {
      this._alertService.onWarningMessage(this._translateService.instant('onWarningMessage.text'), this._translateService.instant('onWarningMessage.title'));
    }
    if (event.rejectedFiles.length > 0) {
      this._alertService.onErrorMessage(this._translateService.instant('onErrorMessage.text'), this._translateService.instant('onErrorMessage.title'));
    }

  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }


  constructor(private _coreTranslationService: CoreTranslationService,private _translateService: TranslateService,private _toastrService: ToastrService, private _alertService: AlertsService) {
    this._coreTranslationService.translate(english, german);
  }

  ngOnInit(): void {
    if (this.existingImages && this.existingImages.length > 0) {
      this.fillFilesArr(this.existingImages);
    }
  }


  fillFilesArr(files) {
    for (let i of files) {
      this.toDataURL(itemImagesPath + i)
          .then(dataUrl => {
            const fileData = this.dataURLtoFile(dataUrl, i);
            this.files.push(fileData);
            this.uploadedFiles.emit(this.files);
          })
    }
  }

  toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

}

