import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'randomNumbers'
})
export class RandomnumbersPipe implements PipeTransform {
    /**
     * Transform
     * Generate Rabdom digits
     *
     * @returns string
     * @param orderId
     */
    transform(orderId): string {
        return Math.floor(10 + Math.random() * 90) + String(orderId);
        // const min = 1;
        // const max = 999;
        // return Math.floor(Math.random() * (max - min + 1) + min)
    }
}
