import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from 'environments/environment';
import {AuthenticationService} from 'app/auth/service';
import {CoreConfigService} from "../../../@core/services/config.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    /**
     *
     * @param injector
     * @param {AuthenticationService} _authenticationService
     * @param _coreConfigService
     */
    constructor(private injector: Injector,private _authenticationService: AuthenticationService, private _coreConfigService: CoreConfigService) {
    }

    /**
     * Add auth header with jwt if user is logged in and request is to api url
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this._authenticationService.currentUserValue;
        const token = localStorage.getItem('token');
        let userLanguage = this._coreConfigService.getDefaultLanguage();
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (currentUser && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    UserDefaultLanguage: userLanguage,

                }
            });
        }

        return next.handle(request);
    }
}
