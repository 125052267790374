<!-- Ecommerce Item -->
<div class="item-img text-center">
    <a [routerLink]="['/items/details', product.id, product.title ]">
        <img class="img-fluid card-img-top" src="{{backendPath}}{{ product.image1 }}" alt="{{product.title}}"/>
    </a>
</div>
<div class="card-body">
    <div class="item-wrapper">
        <div class="item-rating">
            <div class="rating rating-sm">
                <app-raiting-section [rateValue]="product.rating"></app-raiting-section>
            </div>
        </div>
        <div class="item-cost">
            <h6 class="item-price">{{'item.from'|translate}} {{ product.dayCost }} €</h6>
        </div>
    </div>
    <h6 class="item-name">
        <a class="text-body" [routerLink]="['/items/details', product.id, product.title ]"> {{ product.title }}</a>
        <span class="card-text item-company"
        >{{'item.buttons.by'|translate}} <a [routerLink]="['/account/profile/', product.created_by_name,product.created_by_id]"
               class="company-name">{{ product.created_by_name }}</a></span
        >

    </h6>
    <p class="card-text item-description">
        {{ product.description }}
    </p>
</div>
<div *ngIf="!isAdmin2" class="item-options text-center">
    <div class="item-wrapper">
        <div class="item-cost">
            <h4 class="item-price">{{'item.from'|translate}} {{ product.dayCost }} €</h4>
            <p class="card-text shipping" *ngIf="product.hasFreeShipping">
                <span class="badge badge-pill badge-light-success">{{'item.free_shipping'|translate}} </span>
            </p>
        </div>
    </div>
    <a
            *ngIf="isWishlistOpen === true"
            class="btn btn-light btn-wishlist"
            (click)="toggleWishlist(product)"
            rippleEffect
    >
        <i data-feather="x"></i>
        <span>{{'item.buttons.remove'|translate}}</span>
    </a>
    <a
            *ngIf="isWishlistOpen !== true && !isEditable && isLoggedIn"
            class="btn btn-light btn-wishlist"
            (click)="toggleWishlist(product)"
            rippleEffect
    >
        <i class="fa mr-25" [ngClass]="product.isInWishlist === true ? 'fa-heart text-danger' : 'fa-heart-o'"></i>
        <span>{{'item.wishlist'|translate}} </span>
    </a>
    <a
            *ngIf="isEditable"
            class="btn btn-danger btn-wishlist"
            [swal]="SweetAlertConfirmColor"
            rippleEffect
    >
        <i class="bi bi-trash3 fa mr-25"></i>
        <span>{{'item.buttons.delete'|translate}}</span>
    </a>
    <a *ngIf="!isEditable" [routerLink]="['/items/details', product.id, product.title ]"
       class="btn btn-primary btn-cart"
       rippleEffect
    >
        <i data-feather="shopping-cart" class="mr-25"></i>
        <span class="add-to-cart">{{'item.buttons.rent'|translate}}</span>
    </a>

    <a *ngIf="isEditable" [routerLink]="'/account/my-store/item-edit/' + product.id"
       class="btn btn-primary btn-cart"
       rippleEffect
    >
        <i data-feather="edit-2" class="mr-25"></i>
        <span class="add-to-cart"> {{'item.buttons.edit'|translate}}</span>
    </a>
</div>



<!--start of admin 2 permessions-->
<div *ngIf="isAdmin2" class="item-options text-center">
    <div class="item-wrapper">
        <div class="item-cost">
            <h4 class="item-price">{{'item.from'|translate}} {{ product.dayCost }} €</h4>
            <p class="card-text shipping" *ngIf="product.hasFreeShipping">
                <span class="badge badge-pill badge-light-success">{{'item.free_shipping'|translate}} </span>
            </p>
        </div>
    </div>
    <a
            class="btn btn-danger btn-wishlist"
            [swal]="SweetAlertConfirmColor"
            rippleEffect
    >
        <i class="bi bi-trash3 fa mr-25"></i>
        <span>{{'item.buttons.delete'|translate}}</span>
    </a>
    <a [routerLink]="['/items/details', product.id, product.title ]"
       class="btn btn-primary btn-cart"
       rippleEffect>
        <i data-feather="shopping-cart" class="mr-25"></i>
        <span class="add-to-cart"> {{'admin.preview'|translate}}</span>
    </a>

</div>

<swal #SweetAlertConfirmColor (open)="OnItemDelete()"></swal>
