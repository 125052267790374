import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';

import {BehaviorSubject, Observable} from 'rxjs';
import {User} from "../../../auth/models";
import {AuthenticationService} from "../../../auth/service";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class NotificationsMainService {
    public user: User;
    public notifications: any[];
    public userOrderList: any[];
    public selectedNotifications: any[];
    public openedNotification;
    public searchText = '';
    public unReadInboxCount;
    public isNotificationDetailOpen;
    public tempNotifications: any[];
    public selectedNotification;
    public onSelectedNotificationChange: BehaviorSubject<any>;

    public onNotificationsChanged: BehaviorSubject<any>;
    public onUserOrderListChanged: BehaviorSubject<any>;
    public onSelectedNotificationsChanged: BehaviorSubject<any>;
    public onOpenedNotificationChanged: BehaviorSubject<any>;
    public onSearchTextChanged: BehaviorSubject<any>;
    public onUnreadInboxCountChanged: BehaviorSubject<any>;
    public onNotificationDetailChanged: BehaviorSubject<boolean>;
    private selectedNotificationId;

    /**
     *
     * @param {HttpClient} _httpClient
     * @param {Router} router
     * @param _authenticationService
     */
    constructor(private _httpClient: HttpClient, private router: Router, private _authenticationService: AuthenticationService) {
        this.user = this._authenticationService.currentUserValue;
        this.selectedNotifications = [];
        this.isNotificationDetailOpen = false;
        this.onNotificationsChanged = new BehaviorSubject([]);
        this.onUserOrderListChanged = new BehaviorSubject([]);
        this.onSelectedNotificationChange = new BehaviorSubject({});
        this.onSelectedNotificationsChanged = new BehaviorSubject([]);
        this.onOpenedNotificationChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject('');
        this.onUnreadInboxCountChanged = new BehaviorSubject([]);
        this.onNotificationDetailChanged = new BehaviorSubject(false);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.selectedNotificationId = route.params.id;
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.getUserNotifications(),
                this.getUserOrderList(),
                this.getSelectedNotification()
            ]).then(() => {
                // close Opened Notification on router change
                this.closeNotificationDetails();

                // deselect Selected Notifications on router change
                this.deselectNotifications();

                resolve();
            }, reject);
        });
    }


    /**
     * Get Notification
     */
    getUserNotifications() {
        const apiUrl = `${environment.apiUrl}/notification`;
        return new Promise<void>((resolve, reject) => {
            this._httpClient.get(apiUrl).subscribe((response: any) => {
                this.notifications = response.data;
                this.tempNotifications = response.data;
                this.onNotificationsChanged.next(this.notifications);
                this.updateUnreadCount()
                resolve();
            }, reject);
        });
    }


    /**
     * get user order list
     */
    getUserOrderList() {
        const apiUrl = `${environment.apiUrl}/notification/getuserorderslist`;
        return new Promise<void>((resolve, reject) => {
            this._httpClient.get(apiUrl).subscribe((response: any) => {
                this.userOrderList = response.data;
                this.onUserOrderListChanged.next(this.userOrderList);
                resolve();
            }, reject);
        });
    }


    /**
     * Set/Update Folder On Selected Notification
     *
     * @param folderId
     */
    updateFolderOnSelectedNotification(folderRef): void {
        this.selectedNotifications.map(notification => {
            notification.folder = folderRef;
            this.updateNotification(notification);
        });
        this.deselectNotifications();

        // close Opened notification
        this.closeNotificationDetails();
    }

    deleteSelectedNotifications(): void {
        this.selectedNotifications.map(notification => {
            this.deleteNotification(notification);
        });
        this.deselectNotifications();

        // close Opened notification
        this.closeNotificationDetails();
    }


    getSelectedNotification() {
        if (this.selectedNotificationId) {
            return new Promise((resolve, reject) => {
                this._httpClient.get(`${environment.apiUrl}/notification/getnotificationbyid/` + this.selectedNotificationId).subscribe((response: any) => {
                    this.selectedNotification = response.data;
                    if (this.selectedNotification) {
                        this.onSelectedNotificationChange.next(this.selectedNotification[0]);
                    }
                    resolve(this.selectedNotification);
                }, reject);
            });
        }
    }

    /**
     * Update Un-read notifications Count
     *
     */


    updateUnreadCount() {
        const apiUrl = `${environment.apiUrl}/notification`
        return new Promise<void>((resolve, reject) => {
            this._httpClient.get(apiUrl).subscribe((response: any) => {
                const notifications = response.data;
                const unReadNotification = notifications.filter(notification => !notification.isRead);
                this.unReadInboxCount = unReadNotification.length;
                this.onUnreadInboxCountChanged.next(this.unReadInboxCount);
                resolve();
            }, reject);
        });
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedNotifications.length > 0) {
            this.deselectNotifications();
        } else {
            this.selectnotifications();
        }
    }

    /**
     * Select notifications
     *
     */
    selectnotifications(): void {
        this.selectedNotifications = JSON.parse(JSON.stringify(this.notifications));
        this.onSelectedNotificationsChanged.next(this.selectedNotifications);
    }

    /**
     * Deselect notification
     */
    deselectNotifications(): void {
        this.selectedNotifications = [];

        // Trigger the next event
        this.onSelectedNotificationsChanged.next(this.selectedNotifications);
    }

    /**
     * Toggle Selected notification By Id
     *
     * @param id
     */
    toggleSelectedMail(id): void {
        // First, check if we already have that notification as selected...
        if (this.selectedNotifications.length > 0) {
            for (const notification of this.selectedNotifications) {
                // ...delete the unselected notification
                if (notification.id === id) {
                    const index = this.selectedNotifications.indexOf(notification);

                    if (index !== -1) {
                        this.selectedNotifications.splice(index, 1);

                        // Trigger the next event
                        this.onSelectedNotificationsChanged.next(this.selectedNotifications);

                        // Return
                        return;
                    }
                }
            }
        }

        // If we don't have it, push as selected
        this.selectedNotifications.push(
            this.notifications.find(notification => {
                return notification.id === id;
            })
        );

        // Trigger the next event
        this.onSelectedNotificationsChanged.next(this.selectedNotifications);
    }

    /**
     * Toggle Starred
     *
     * @param notification
     */
    toggleStarred(notification) {
        notification.isStarred = notification.isStarred === false;
        this.updateNotification(notification);
    }

    /**
     * Update notification
     *
     * @param notification
     * @returns {Promise<any>}
     */
    updateNotification(notification): Promise<any> {
        const newNotificationOnj = {
            unSeen: notification.unSeen,
            isStarred: notification.isStarred,
            isRead: notification.isRead
        };

        const apiUrl = `${environment.apiUrl}/notification/${notification.id}`;
        return new Promise((resolve, reject) => {
            this._httpClient.put(apiUrl, newNotificationOnj).subscribe(response => {
                this.getUserNotifications().then(notifications => {
                    resolve(notifications);
                }, reject);
            });
        });
    }

    deleteNotification(notification): Promise<any> {
        const apiUrl = `${environment.apiUrl}/notification/${notification.id}`;
        return new Promise((resolve, reject) => {
            this._httpClient.delete(apiUrl).subscribe(response => {
                this.getUserNotifications().then(notifications => {
                    resolve(notifications);
                }, reject);
            });
        });
    }

    /**
     * Set/Update Opened notification
     *
     * @param openedNotification
     */
    setOpenedNotification(openedNotification) {
        this.selectedNotifications[0] = openedNotification;
        this.onSelectedNotificationsChanged.next(this.selectedNotifications);
    }

    /**
     * Set/Update Opened notification by id
     *
     * @param id
     */
    openNotificationDetails(id): void {
        this.openedNotification = this.notifications.find(notification => {
            return notification.id === id;
        });
        this.onOpenedNotificationChanged.next(this.openedNotification);
        // Set opened mail mark as read
        this.markAsRead(id);
    }

    /**
     * Close notification Details
     */
    closeNotificationDetails() {
        this.isNotificationDetailOpen = false;
        this.onNotificationDetailChanged.next(this.isNotificationDetailOpen);
    }

    /**
     * Read notification
     *
     * @param id
     */
    markAsRead(id) {
        this.selectedNotifications.map(notification => {
            if (notification.id === id) {
                notification.isRead = true;
            }
            this.updateNotification(notification);
        });
    }

    /**
     * Un-read notification
     *
     */
    markAsUnread(value) {
        this.selectedNotifications.map(notification => {
            notification.isRead = value;
            this.updateNotification(notification);
            this.deselectNotifications();

            // close Opened notification
            this.closeNotificationDetails();
        });
    }


    /**
     * Get notification On Query
     */
    getMailOnQuery(value) {

        const filteredNotification = this.tempNotifications.filter(notification => {
            return notification.title.toLowerCase().includes(value.toLowerCase())
                || notification.content.toLowerCase().includes(value.toLowerCase())
                || notification.senderName.toLowerCase().includes(value.toLowerCase());


        });

        this.notifications = filteredNotification;
        this.onNotificationsChanged.next(this.notifications);
    }

    getUnreadNotificationCounter() {
        const apiUrl = `${environment.apiUrl}/notification/unread`;
        return new Promise<void>((resolve, reject) => {
            this._httpClient.get(apiUrl).subscribe((response: any) => {
                const notifications = response.data;
                const unReadNotification = notifications.filter(notification => !notification.isRead);
                this.unReadInboxCount = unReadNotification.length;
                this.onUnreadInboxCountChanged.next(this.unReadInboxCount);
                resolve();
            }, reject);
        });

    }
}
