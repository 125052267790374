export const locale = {
    lang: 'de',
    data: {
        admin:{
            preview:"Anzeigen"
        },
        general:{
            home:"Startseite",
            included:"inkusive",
            items:"Artikel",
            item_list:"Artikelliste",
            item_status:{
                available: "Verfügbar",
                rented: "Aktuell vermietet"
            }
        },
        related_items: {
            title: "Ähnliche Artikel",
            text: "Menschen suchen auch nach diesen Artikel"
        },
        contentHeader:{
            headerTitle: 'Artikel Details',
            link_1:'Startseite',
            link_2: 'Artikel',
            link_3: 'Details',

        },
        categories: {
            cameras: "Kameras & Fotos",
            fashion: "Mode",
            family: "Familie & Kinder",
            tools: "Werkzeuge",
            home: "Home",
            emobility: "E-Mobilität",
            drones: "Drohnen",
            electronics: "Elektronik",
            sport: "Sport",
            events: "Events",
            entertainment: "Entertainment",
            transport: "Transport",
            travel: "Reisen",
            other: "Andere"
        },
        checkout: {
            price_details: {
                rental_period: 'Mietdauer',
                total_days: 'Gesamttage',
                subtotal: 'Zwischensumme',
                service_fees: 'Servicegebühr',
                total_amount: '',
                step_1: 'Schritt 1',
                step_2: 'Schritt 2',
                price_details:"Preisdetails",
                coupon:"Gutschein..."
            },
            component: {
                checkout: 'Zur Kasse',
                metadata:{
                    description: 'Hol dir die besten Angebote in unserem Platform. Kaufe jetzt eine große Auswahl an Produkten zu erschwinglichen Preisen.',
                    keywords: 'Online-Shopping, Kasse, Zahlung, Produkte, Angebote, Rabatte, erschwingliche Preise.'
                },
                item_and_details: 'Artikel und Details',
                response: 'Rückmeldung',
                wait_response: 'Auf Rückmeldung warten'

            },
            buttons: {
                add_to_wishlist: 'Zur Wünschliste hinzufügen',
                apply: 'Anwenden',
                loading: 'Laden...',
                place_order:"Bestellung aufgeben"
            }
        },
        details: {
            title:"{{itemName}}",
            metadata:{
                description: 'Erfahre mehr über {{itemName}} und kaufe es auf unserer Website. Sieh dir Produktinformationen, Spezifikationen und Bewertungen an.',
                keywords: '{{itemName}}, Produktinformationen, Produktspezifikationen, Produktbewertungen, E-Commerce, Online-Shopping,online-renting'
            },
            date:"Wähle deinen gewünschten Datumsbereich aus",
            send_request:"Anfrage senden",
            isItemCreator:"Oops, du kannst keine Anfrage an dich selbst schicken.",
            wishlist: "Wunschliste",
            day: 'Tag',
            week: 'Woche',
            month: 'Monat',
            discount:"Rabatt wird abgezogen"
        },
        item: {
            from: 'Ab ',
            by: 'Von ',
            free_shipping: 'Versandkostenfrei',
            wishlist: 'Wunschliste',
            buttons: {
                delete: 'Löschen',
                rent: 'Miete',
                edit: 'Bearbeiten',
                remove: 'Entfernen',
            }
        },
        shop: {
            sidebar: {
                filters: 'Filter',
                multi_range: 'Mehrere Bereiche',
                price_range: 'Preisklasse',
                categories: 'Kategorien',
                ratings: 'Bewertungen',
                up: ' & Up ',
                clear_filter: 'Alle Filter löschen'
            },
            shop_component: {
                title: "Ecommerce Shop",
                metadata:{
                    description: 'Finde alles, jederzeit und alles, was du zu niedrigen Preisen mieten willst.',
                    keywords: 'Entdecken, Artikel, Gegenstände, Mieten, Niedrige Preise, Miete Artikel online, Miete alles aus, was du brauchst, Teile Gegenstände einfach, Miete Artikel in deiner Nähe, Erschwingliche Mieten, Nachhaltiges Teilen, Leihe alles aus, was du brauchst, Finde wonach du suchst, Teilen leicht gemacht, Spare Geld durch Vermietung, Nachhaltiger Konsum, Dein Teilen-Marktplatz, Miete und vermiete einfach, Entdecke neue Möglichkeiten, Unterstütze lokales Teilen, Nachhaltiger Lebensstil leicht gemacht, Probier bevor du kaufst'
                },
                search_item: 'Artikel suchen...',
                sorry: 'Sorry, Kein Ergebnis gefunden!',
                reset_filter: 'Filter zurücksetzen!',
                result_found: 'Ergebnisse gefunden',
                featured: 'Featured',
                lowest: 'Niedrigste',
                highest: 'Höchste',
                item: 'Artikel'
            }
        },
        wishlist: {
            title: "Wunschliste",
            metadata:{
                description: 'Speichere deine favorisierten Artikel und verwalte deine Wunschliste mit unserem praktischen Tool',
                keywords: 'Wunschliste, Favoriten, Merkliste, Produktliste, Geschenkideen, Favoritenverwaltung, Artikel speichern, Wunschartikel, Shopping-Liste, Produktmerkliste'
            },
            no_results: 'Keine gemerkte Artikel!',
            buttons: {
                explore_items: 'Artikel Entdecken'
            }
        },
        alerts:{
            onItemDelete:{
                title: 'Bist du sicher, dass du {{item}} löschen möchtest ?',
                text: "Du kannst das nicht rückgängig machen!",
                confirmButtonText: 'Ja, löschen!',
            },
            onError:{
                title: 'Fehler gefunden',
                text: 'Ein Fehler ist aufgetreten, bitte versuche es später erneut!',
            },
            afterDelete:{
                title: 'Gelöscht!',
                text1: 'Artikel "',
                text2: '" wurde gelöscht.',
            }
        }
    }
};
