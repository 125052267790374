export const locale = {
    lang: 'de',
    data: {
        title: "Anfragen",
        search:"Suche...",
        metadata: {
            description: 'Sende und empfange Gegenstands-Anfragen schnell und effizient',
            keywords: 'Gegenstands-Anfragen, Sharing-Plattform, Anfrage senden, Anfrage akzeptieren, Anfrageliste, Gegenstands-Sharing, Sharing-Community, Anfragesystem'
        },
        list: {
            days: "Tage",
            item:"Artikel",
            client:"Kunde",
            period: "Start - Ende",
            issued_date: "Ausstellungsdatum",
            total: "Gesamt",
            status: "Status",
            actions: "Aktionen"
        },
        order_status: {
            pending: 'Auf Antwort warten',
            accepted: 'Anfrage genehmigt',
            completed: 'Erfolgreich zurückgegeben',
            rejected: 'Anfrage abgelehnt',
            cancelled: 'Die Anfrage storniert',
            rented: 'Aktuell vermietet'
        },
        buttons: {
            explore_items: 'Artikel Entdecken',
            search: 'Suchen...',
            select_status: 'Status auswählen',
            reject:'Anfrage ablehnen',
            accept:'Anfrage akzeptieren',
            send:'Nachricht senden',
            invoice:'Rechnungsvorschau'
        },
        order_status_options: {
            all: 'Alle',
            accepted: 'Akzeptiert',
            cancelled: 'Storniert',
            completed: 'Abgeschlossen',
            pending: 'Ausstehend',
            rented: 'Vermietet',
            rejected: 'Abgelehnt'
        },
        typescripts_files: {
            onReject: {
                title: 'Bist du sicher, dass du die Anfrage ablehnen?',
                text: 'Du kannst nicht dies rückgängig machen!',
                confirmButtonText: 'Ja, ablehnen!',
                success_msg: {
                    title: 'Abgelehnt!',
                    text: 'Deine Anfrage wurde abgelehnt.'
                },
                err_msg: {
                    title: 'Fehler!',
                    text: 'Ein Fehler ist aufgetreten, versuche es erneut'
                }

            }
        }
    }
};
