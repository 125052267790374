import {NgModule} from '@angular/core';

import {FilterPipe} from '@core/pipes/filter.pipe';
import {RandomnumbersPipe} from '@core/pipes/randomnumbers.pipe';


import {InitialsPipe} from '@core/pipes/initials.pipe';

import {SafePipe} from '@core/pipes/safe.pipe';
import {StripHtmlPipe} from '@core/pipes/stripHtml.pipe';
import {LocalenumberPipe} from "./localenumber.pipe";
import {CategoryPipe} from "./category.pipe";
import {CategoryIconPipe} from "./categoryicon.pipe";

@NgModule({
    declarations: [InitialsPipe, FilterPipe, RandomnumbersPipe, LocalenumberPipe, CategoryPipe, CategoryIconPipe, StripHtmlPipe, SafePipe],
    imports: [],
    exports: [InitialsPipe, FilterPipe, RandomnumbersPipe, LocalenumberPipe, StripHtmlPipe, CategoryIconPipe, CategoryPipe, SafePipe]
})
export class CorePipesModule {
}
