<!-- ngb-alert component -->
<div class="content-wrapper container-xxl p-0">
    <div *ngIf="!alertClose">
        <ngb-alert [type]="type" (closed)="alertClose = !alertClose">
            <div class="alert-body">
                <span [data-feather]="'info'" [class]="'mr-50 align-middle'"></span>
                <span>{{text}}</span>
            </div>
        </ngb-alert>
    </div>

</div>