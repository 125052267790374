import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../../auth/service";

@Component({
  selector: 'app-navigate-to-dashboard',
  templateUrl: './navigate-to-dashboard.component.html',
  styleUrls: ['./navigate-to-dashboard.component.scss']
})
export class NavigateToDashboardComponent implements OnInit {

  constructor(private _authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

    onClick() {
        this._authenticationService.routToDashboard();
    }
}
