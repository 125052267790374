import {Injectable} from '@angular/core';
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {locale as german} from 'app/main/utils/notifications_i18n/i18n/de';
import {locale as english} from 'app/main/utils/notifications_i18n/i18n/en';

@Injectable({
    providedIn: 'root'
})
export class NotificationsI18nService {

    constructor(private _coreTranslationService: CoreTranslationService, private _translateService: TranslateService) {
        this._coreTranslationService.translate(english, german);
    }


    getTranslatedNotification(notification): { title: string, text: string } {

        switch (String(notification.code)) {
            case '1': {
                return {
                    title: this._translateService.instant('welcomeTemplate.title'),
                    text: this._translateService.instant('welcomeTemplate.text')
                }
                break;
            }
            case '2': {
                return {
                    title: this._translateService.instant('newRequestTemplate.title',),
                    text: this._translateService.instant('newRequestTemplate.text',{sender:notification.senderName})
                }
                break;
            }
            case '3': {
                return {
                    title: this._translateService.instant('requestRejectedTemplate.title'),
                    text: this._translateService.instant('requestRejectedTemplate.text',{user:notification.senderName})
                }
                break;
            }
            case '4': {
                return {
                    title: this._translateService.instant('requestCanceledTemplate.title'),
                    text: this._translateService.instant('requestCanceledTemplate.text',{user:notification.senderName})
                }
                break;
            }
            case '5': {
                return {
                    title: this._translateService.instant('requestAcceptedTemplate.title'),
                    text: this._translateService.instant('requestAcceptedTemplate.text',{user:notification.senderName})
                }
                break;
            }
            case '6': {
                return {
                    title: this._translateService.instant('sentRequestTemplate.title'),
                    text: this._translateService.instant('sentRequestTemplate.text',{lender:notification.senderName})
                }
                break;
            }
        }
    }

}
