import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {AuthenticationService} from "../../../../auth/service";

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    // Public
    public unseenMsg;
    public onUnseenMsgsDataChange: BehaviorSubject<any>;

  /**
   *
   * @param _httpClient
   * @param _authenticationService
   */
    constructor(private _httpClient: HttpClient, private _authenticationService: AuthenticationService) {
        this.onUnseenMsgsDataChange = new BehaviorSubject('');
        this.getUnseenMsg();
    }

    /**
     * GetUnreedMsgs Data
     */
    getUnseenMsg(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get((`${environment.apiUrl}/contacts/unseenmsg`)).subscribe((response: any) => {
                this.unseenMsg = response.data.unseenMsgsSum;
                this.onUnseenMsgsDataChange.next(this.unseenMsg);
                resolve(this.unseenMsg);
            }, reject);
        });
    }
}
