import {OrderStatus} from './OrderStatus';

export class Order {
    itemDescription: string
    discountInCurrency: number
    discountInPercentage: number
    end: any
    issuedDate: any
    itemID: string
    lenderStreet: string
    lenderZipcode: string
    itemTitle: string
    itemcategory: string
    itemcreated_by_id: number
    itemcreated_by_name: string
    lenderCity: string
    lenderIsCompany: string
    lenderCountry: string
    lenderEmail: string
    lenderID: number
    lenderName: string
    lenderPhone: string
    orderID: number
    start: string | Date
    dueDate?: string | Date
    status: OrderStatus
    subTotal: number
    totalAmount: number
    totalDays: number | null
    item: string
    renterConfirmation1: boolean
    lenderConfirmation1: boolean
    lenderConfirmation2: boolean
    renterConfirmation2: boolean
    id: number
    lenderIban: string
    isLender: boolean
    isRenter: boolean
    renterServiceFees: number
    renterservicefeesText: string
    lenderserviceFees: number
    lenderServicefeesText: string

}
