import {Pipe, PipeTransform} from '@angular/core';
import {itemcategories} from "../../app/main/apps/utils/itemcategories";

@Pipe({
    name: 'categoryicon'
})
export class CategoryIconPipe implements PipeTransform {

    /**
     * get Category Icon
     * @param value
     * @param lang
     */
    transform(value: any) {
        if (value) {
            const categories = itemcategories;

            for (let cat of categories) {
                if (String(cat.id) === value)
                    return cat.icon;
            }

        }
        return '';

    }
}
