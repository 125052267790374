<section id="faq-search-filter">
    <div class="card faq-search" style="background-image: url('assets/images/banner/banner.png')">
        <div class="card-body text-center">
            <!-- main title -->
            <h2 class="text-primary">{{title}}</h2>

            <!-- subtitle -->
            <p class="card-text mb-2">{{sub_title}}</p>

            <!-- search input -->
<!--            <form class="faq-search-input">-->
<!--                <div class="input-group input-group-merge">-->
<!--                    <div class="input-group-prepend">-->
<!--                        <div class="input-group-text">-->
<!--                            <i data-feather="search"></i>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <input-->
<!--                            type="text"-->
<!--                            [(ngModel)]="searchText"-->
<!--                            name="searchbar"-->
<!--                            class="form-control"-->
<!--                            placeholder="Search faq..."-->
<!--                    />-->
<!--                </div>-->
<!--            </form>-->
        </div>
    </div>
</section>
