import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CoreConfigService} from "../../../../../@core/services/config.service";
import _ from "lodash";

@Component({
    selector: 'app-navbar-language',
    templateUrl: './navbar-language.component.html',
    styleUrls: ['./navbar-language.component.scss']
})
export class NavbarLanguageComponent implements OnInit {
    languageOptions: any;
    public selectedLanguage: any;

    ngOnInit(): void {
        // Set the selected language from default languageOptions
        this.selectedLanguage = _.find(this.languageOptions, {
            id: this._translateService.currentLang
        });
    }


    constructor(public _translateService: TranslateService, private _coreConfigService: CoreConfigService,) {
        this.languageOptions = {
            en: {
                title: 'English',
                flag: 'us'
            },
            de: {
                title: 'German',
                flag: 'de'
            },
        };
    }

    setLanguage(language): void {
        // Set the selected language for the navbar on change
        this.selectedLanguage = language;

        // Use the selected language id for translations
        this._translateService.use(language);

        this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
    }

}
