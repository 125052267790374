import {Pipe, PipeTransform} from '@angular/core';
import {itemcategories} from "../../app/main/apps/utils/itemcategories";

@Pipe({
    name: 'category'
})
export class CategoryPipe implements PipeTransform {

    /**
     * get Category label
     * @param value
     * @param lang
     */
    transform(value: string, lang: string) {
        if (value) {
            const categories = itemcategories;

            for (let cat of categories) {
                if (String(cat.id) === value)
                    return cat.label;
            }

        }
        return '';

    }
}
