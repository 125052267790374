export const environment = {
    production: false,
    hmr: false,
    apiUrl: 'https://api.colshab.com', //used for local
    socketApiUrl: 'https://api.colshab.com',
    _googleApiKey: 'AIzaSyA49nmJkQ2k3pMmUmN-W385DQBOm4iAqhg',
    _facebookAppId: '1321939378360342',
    _googleClientId: '981820056822-ojf7sk15natkun5hspulfn6ts1pl60bn.apps.googleusercontent.com',
    _facebookSocialAccount: 'https://www.facebook.com/colshab22',
    _instagramSocialAccount: 'https://www.instagram.com/colshab/',
    _twitterSocialAccount: 'https://twitter.com/Colshab23',
    _socket_io: 'https://api.colshab.com',
    _stripePk: "pk_live_51MxZq1E0pNJdSyrxUoX6TbeRsNfyyeUegsgXJjwpIKn6h9iCrvnPbtF35KxgnRdv8XKOHLSmvXlBHtPEFO3wV4QN00eEO89qiM",
    _stripePrivateKey: "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"
};


export const websiteUrl = 'https://colshab.com';
export const itemImagesPath = 'https://api.colshab.com/item-images/'; //used for Server
export const profileImagesPath = 'https://api.colshab.com/profile-images/';
export const blogImagesPath = 'https://api.colshab.com/blog-images/';


