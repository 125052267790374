import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SwiperModule} from "ngx-swiper-wrapper";
import { RaitingSectionComponent } from './raiting-section/raiting-section.component';
import {NgbModule, NgbRatingModule} from "@ng-bootstrap/ng-bootstrap";
import { ErrorResponseComponent } from './error-response/error-response.component';
import { SuccessResponseComponent } from './success-response/success-response.component';
import { BusinessTemplateComponent } from './business-template/business-template.component';
import { SuccessResponseNoPopupComponent } from './success-response-no-popup/success-response-no-popup.component';
import { ErrResponseNoPopupComponent } from './err-response-no-popup/err-response-no-popup.component';
import {CoreCommonModule} from "../../../@core/common.module";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import { HeroSectionComponent } from './hero-section/hero-section.component';
import { CookiesDialogComponent } from './cookies-dialog/cookies-dialog.component';
import { ItemsFeaturesComponent } from './items-features/items-features.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ImagesUploaderSectionComponent } from './images-uploader-section/images-uploader-section.component';
import {NgxDropzoneModule} from "ngx-dropzone";
import { OrderStatusLabel2Component } from './order-status-label2/order-status-label2.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { ColshabAlertsComponent } from './colshab-alerts/colshab-alerts.component';
import { BlockAndLoadingComponent } from './block-and-loading/block-and-loading.component';

@NgModule({
    declarations: [
        RaitingSectionComponent,
        ErrorResponseComponent,
        SuccessResponseComponent,
        BusinessTemplateComponent,
        SuccessResponseNoPopupComponent,
        ErrResponseNoPopupComponent,
        HeroSectionComponent,
        CookiesDialogComponent,
        ItemsFeaturesComponent,
        NotificationsComponent,
        ImagesUploaderSectionComponent,
        OrderStatusLabel2Component,
        UserAvatarComponent,
        ColshabAlertsComponent,
        BlockAndLoadingComponent,

    ],
    exports: [
        RaitingSectionComponent,
        SuccessResponseComponent,
        ErrorResponseComponent,
        BusinessTemplateComponent,
        SuccessResponseNoPopupComponent,
        ErrResponseNoPopupComponent,
        HeroSectionComponent,
        CookiesDialogComponent,
        ItemsFeaturesComponent,
        NotificationsComponent,
        ImagesUploaderSectionComponent,
        OrderStatusLabel2Component,
        UserAvatarComponent,
        ColshabAlertsComponent,
        BlockAndLoadingComponent,
    ],
    imports: [
        CommonModule,
        SwiperModule,
        NgbRatingModule,
        CoreCommonModule,
        TranslateModule,
        RouterModule,
        NgxDropzoneModule,
        NgbModule
    ]
})
export class ComponentsModule { }
