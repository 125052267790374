import {Injectable} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {websiteUrl} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SeoSupportService {
    currentUrl: string;


    constructor(private titleService: Title, private metaService: Meta, private router: Router) {

    }

    /**
     * set page title
     * @param title
     */
    setTitle(title: string) {
        this.titleService.setTitle('Colshab - ' + title);
    }

    /**
     * set page metaData
     * @param description
     * @param keywords
     */
    setMetaTags(data) {
        const canonicalUrl = websiteUrl + this.router.routerState.snapshot.url;
        // this.titleService.setTitle('Colshab- '+data.title);
        this.metaService.updateTag({name: 'description', content: data.description});
        this.metaService.updateTag({name: 'keywords', content: data.keywords});
        this.metaService.updateTag({name: 'author', content: 'Colshab - Mohammad Nmeri'});

        // Set Open Graph meta tags
        this.metaService.updateTag({property: 'og:title', content: data.title ? data.title : 'Colshab'});
        this.metaService.updateTag({property: 'og:type', content: data.og_type ? data.og_type : 'website'});
        this.metaService.updateTag({property: 'og:url', content: canonicalUrl});
        this.metaService.updateTag({
            property: 'og:image',
            content: data.image ? data.image : 'https://www.colshab.com/assets/images/logo/1672352982509-logo.png'
        });
        this.metaService.updateTag({property: 'og:description', content: data.description});
        this.metaService.updateTag({property: 'og:site_name', content: 'Colshab- ' + data.title});
        this.metaService.updateTag({property: 'og:locale', content: navigator.language});
        this.metaService.updateTag({property: 'og:site_name', content: 'Colshab'});

        // Set Twitter Card meta tags
        this.metaService.updateTag({name: 'twitter:title', content: data.title ? data.title : 'Colshab'});
        this.metaService.updateTag({name: 'twitter:site', content: '@Colshab2023'});
        this.metaService.updateTag({name: 'twitter:creator', content: '@Colshab2023'});
        this.metaService.updateTag({name: 'twitter:card', content: data.og_type ? 'summary_large_image' : 'summary'});
        this.metaService.updateTag({name: 'twitter:url', content: canonicalUrl});
        this.metaService.updateTag({
            name: 'twitter:image',
            content: data.image ? data.image : 'https://www.colshab.com/assets/images/logo/1672352982509-logo.png'
        });
        this.metaService.updateTag({name: 'twitter:description', content: data.description});


        this.setCanonicalUrl(canonicalUrl);
    }

    /**
     * set page title
     */
    setCanonicalUrl(canonicalUrl) {
        this.metaService.updateTag({name: 'canonical', content: canonicalUrl});
    }


}
