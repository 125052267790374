import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CoreTranslationService} from '@core/services/translation.service';
import {EcommerceService} from 'app/main/apps/ecommerce/ecommerce.service';
import {locale as german} from 'app/main/apps/ecommerce/i18n/de';
import {locale as english} from 'app/main/apps/ecommerce/i18n/en';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {SeoSupportService} from "../../../utils/seo-support.service";
import {CoreConfigService} from "../../../../../@core/services/config.service";


@Component({
    selector: 'app-ecommerce-wishlist',
    templateUrl: './ecommerce-wishlist.component.html',
    styleUrls: ['./ecommerce-wishlist.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {class: 'ecommerce-application'}
})
export class EcommerceWishlistComponent implements OnInit {
    // Public
    public contentHeader: object;
    public products;
    public wishlist;

    /**
     *
     * @param _seoService
     * @param {EcommerceService} _ecommerceService
     * @param _translateService
     * @param _coreTranslationService
     */
    constructor(private _coreConfigService: CoreConfigService, private _seoService: SeoSupportService, private _ecommerceService: EcommerceService, private _translateService: TranslateService, private _coreTranslationService: CoreTranslationService) {
        this._coreTranslationService.translate(english, german);
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this._coreTranslationService.onSelectedLanguageChange.subscribe((selectedLanguage) => {
            this.setSeoData();
            this.setContentHeader();
        })



        this._ecommerceService.getProducts();
        this._ecommerceService.getWishlist();

        this._ecommerceService.onProductListChange.subscribe(res => {
            this.products = res;
        });

        // Subscribe to Wishlist change
        this._ecommerceService.onWishlistChange.subscribe(res => (this.wishlist = res));

        // update product is in Wishlist : Boolean
        this.products.forEach(product => {
            product.isInWishlist = this.wishlist.findIndex(p => p.itemId === product.id) > -1;
        });

    }

    private setContentHeader() {
        this.contentHeader = {
            headerTitle: this._translateService.instant('wishlist.title'),
            actionButton: true,
            breadcrumb: {
                type: '',
                links: [
                    {
                        name: this._translateService.instant('general.home'),
                        isLink: true,
                        link: '/'
                    },
                    {
                        name: this._translateService.instant('wishlist.title'),
                        isLink: false
                    }
                ]
            }
        };
    }

    private setSeoData() {
        this._seoService.setTitle(this._translateService.instant('wishlist.title'));
        this._seoService.setMetaTags(
            {
                title:this._translateService.instant('wishlist.title'),
                description: this._translateService.instant('wishlist.metadata.description'),
                keywords: this._translateService.instant('wishlist.metadata.keywords')
            }
        )
    }
}
