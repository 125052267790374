import { Component, OnInit } from '@angular/core';
import {translate} from "@angular/localize/tools";
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {locale as english} from "../cookies-dialog/i18n/en";
import {locale as german} from "../cookies-dialog/i18n/de";

@Component({
  selector: 'app-block-and-loading',
  templateUrl: './block-and-loading.component.html',
  styleUrls: ['./block-and-loading.component.scss']
})
export class BlockAndLoadingComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
