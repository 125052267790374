
import {Component, OnInit} from '@angular/core';

import {Subject} from "rxjs";
import {EcommerceService} from "../../../../main/apps/ecommerce/ecommerce.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-navbar-bookmark',
    templateUrl: './navbar-bookmark.component.html'
})
export class NavbarBookmarkComponent implements OnInit {
    // Public
    public products = [];
    public wishList = [];
    public wishListLength;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {EcommerceService} _ecommerceService
     */
    constructor(public _ecommerceService: EcommerceService) {
        this._unsubscribeAll = new Subject();
    }

    // Public Methods
    // -----------------------------------------------------------------------------------------------------

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get Products
        this._ecommerceService.getProducts();
        this._ecommerceService.getWishlist();

        // Subscribe to Cart List
        this._ecommerceService.onWishlistChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.wishList = res;
            this.wishListLength = this.wishList.length;

            if (this.products.length) {
                this.products.forEach(product => {
                    product.isInWishlist = this.wishList.findIndex(p => p.itemId === product.id) > -1;
                });
            }
        });

        // Subscribe to ProductList change
        this._ecommerceService.onProductListChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.products = res;
        });
    }
}
