export const locale = {
    lang: 'de',
    data: {
        part_1:'Magst du Cookies?',
        part_2:'Wir verwenden Cookies, um sicherzustellen, dass du die beste Erfahrung auf unserer Website erhaltest.',
        buttons:{
            btn1:'Mehr erfahren',
            btn2:'akzeptieren',
        }
    }
};
