import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuModule} from '@ctrl/ngx-rightclick';

import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';

import {NotloggedInUserAppCoreConfig} from 'app/app-config';
import {AuthGuard} from 'app/auth/helpers/auth.guards';
import {JwtInterceptor, ErrorInterceptor} from 'app/auth/helpers';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';
import {EcommerceService} from "./main/apps/ecommerce/ecommerce.service";
import {EcommerceWishlistComponent} from "./main/apps/ecommerce/ecommerce-wishlist/ecommerce-wishlist.component";

import {NavigateToDashboardComponent} from './main/utils/navigate-to-dashboard/navigate-to-dashboard.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NotLoggedInGuard} from "./auth/helpers";
import {BackEndDefaultSettingsInterceptor} from "./auth/helpers/defaultbesettings.interceptor";
import {ComponentsModule} from "./main/components/components.module";
import {environment} from "../environments/environment";
import {AgmCoreModule} from "@agm/core";
import {GooglePlaceModule} from "ngx-google-places-autocomplete";
import {SocketIoConfig, SocketIoModule} from "ngx-socket-io";

const socketConfig: SocketIoConfig = {url: `${environment.socketApiUrl}`, options: {}};
const appRoutes: Routes = [
    {
        path: '',
        canActivate: [NotLoggedInGuard],
        loadChildren: () => import('./main/static/static.module').then(m => m.StaticModule)
    },

    {
        path: 'account',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'messages',
                loadChildren: () => import('./main/apps/chat/chat.module').then(m => m.ChatModule)
            },
            // {
            //     path: 'messages5',
            //     loadChildren: () => import('./main/apps/chat-v3/chat-v3.module').then(m => m.ChatV3Module)
            // },
            {
                path: 'payment',
                loadChildren: () => import('./main/checkout-pages/checkout-pages.module').then(m => m.CheckoutPagesModule)

            },
            {
                path: 'notifications',
                loadChildren: () => import('./main/apps/notifications/notification-list.module').then(m => m.NotificationListModule)
            },
            {
                path: 'account-settings',
                loadChildren: () => import('./main/pages/account-settings/account-settings.module').then(m => m.AccountSettingsModule)
            },
            {
                path: 'list-an-item',
                loadChildren: () => import('./main/pages/list-an-item/list-an-item.module').then(m => m.ListAnItemModule)
            },
            {
                path: 'rentals',
                loadChildren: () => import('./main/apps/rentals-v2/rentals-v2.module').then(m => m.RentalsV2Module)
            },
            {
                path: 'requests',
                 loadChildren: () => import('./main/apps/requests-v2/requests-v2.module').then(m => m.RequestsV2Module)

            },
            {
                path: 'invoice',
                loadChildren: () => import('./main/apps/invoice-v2/invoice-v2.module').then(m => m.InvoiceV2Module)
            },
            {
                path: 'wishlist',
                component: EcommerceWishlistComponent,
                resolve: {
                    ecommerce: EcommerceService
                },
                data: {animation: 'EcommerceWishlistComponent'}
            },
            {
                path: 'my-store',
                loadChildren: () => import('./main/pages/my-store/my-store2.module').then((m => m.MyStore2Module))
            },
            {
                path: 'profile',
                loadChildren: () => import('./main/pages/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./main/dashbaord/dashbaord.module').then(m => m.DashbaordModule)
            },
            {
                path: 'pages',
                loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
            }
        ]
    },

    {
        path: 'miscellaneous',
        loadChildren: () => import('./main/pages/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)

    },

    {
        path: 'review',
        loadChildren: () => import('./main/apps/review/review.module').then(m => m.ReviewModule)
    },
    {
        path: 'authentication',
        canActivate: [NotLoggedInGuard],
        loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule)
    },

    {
        path: 'items',
        loadChildren: () => import('./main/apps/ecommerce/ecommerce.module').then(m => m.EcommerceModule)
    },
    {
        path: 'blog',
        loadChildren: () => import('./main/pages/blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./main/pages/faq/faq.module').then(m => m.FaqModule)
    },
    {
        path: 'terms-of-use',
        loadChildren: () => import('./main/static/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./main/static/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    },
    {
        path: 'business',
        loadChildren: () => import('./main/pages/business/business.module').then(m => m.BusinessModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./main/pages/contact-colshab/contact-colshab.module').then(m => m.ContactColshabModule)
    },

    {
        path: '**',
        redirectTo: '/miscellaneous/error-404' //Error 404 - Page not found
    },
];

@NgModule({
    declarations: [
        AppComponent,
        NavigateToDashboardComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AgmCoreModule.forRoot({
            apiKey: environment._googleApiKey,
            libraries: ['places']
        }),
        SocketIoModule.forRoot(socketConfig),
        GooglePlaceModule,
        RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled'
}),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(NotloggedInUserAppCoreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        LayoutModule,
        SweetAlert2Module.forRoot(),
        ContentHeaderModule,
        ComponentsModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: BackEndDefaultSettingsInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
