import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../auth/service";
import {AlertsService} from 'app/auth/service/alerts.service';
import {locale as german} from 'app/i18_services/de';
import {locale as english} from 'app/i18_services/en';
import {TranslateService} from "@ngx-translate/core";
import {CoreTranslationService} from "../../../../@core/services/translation.service";
import {User} from "../../../auth/models";

@Injectable({
    providedIn: 'root'
})
export class EcommerceService implements Resolve<any> {

    public productList: Array<any>;
    public relatedProducts: Array<any>;
    public originalProductList: Array<any>;
    public userProductList: Array<any>;
    public wishlist: Array<any>;
    public selectedProduct;
    public cartItem: any;

    public onProductListChange: BehaviorSubject<any>;
    public onUserProductListChange: BehaviorSubject<any>;
    public onRelatedProductsChange: BehaviorSubject<any>;
    public onWishlistChange: BehaviorSubject<any>;
    public onSelectedProductChange: BehaviorSubject<any>;

    // Private
    private productID: any;

    public sortRef = key => (a, b) => {
        const fieldA = a[key];
        const fieldB = b[key];

        let comparison = 0;
        if (fieldA > fieldB) {
            comparison = 1;
        } else if (fieldA < fieldB) {
            comparison = -1;
        }
        return comparison;
    };

    /**
     * Constructor
     *
     * @param _translateService
     * @param _translateService
     * @param _coreTranslationService
     * @param _alertService
     * @param _authenticationService
     * @param _router
     * @param {HttpClient} _httpClient
     * @param _toastrService
     */
    constructor(private _translateService: TranslateService,
                private _coreTranslationService: CoreTranslationService,
                private _alertService: AlertsService, private _authenticationService: AuthenticationService, private _router: Router, private _httpClient: HttpClient, private _toastrService: ToastrService) {
        this._coreTranslationService.translate(english, german);
        this.onProductListChange = new BehaviorSubject({});
        this.onRelatedProductsChange = new BehaviorSubject({});
        this.onWishlistChange = new BehaviorSubject({});
        this.onUserProductListChange = new BehaviorSubject({});
        this.onSelectedProductChange = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.productID = route.params.id;
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getProducts(), this.getWishlist(), this.getUserlist(), this.getSelectedProduct()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get Products
     */
    getProducts(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/items`).subscribe((response: any) => {
                this.productList = response.data;
                this.originalProductList = response.data;
                this.sortProduct('featured', this.productList); // Default shorting
                resolve(this.productList);
            }, reject);
        });
    }

    /**
     * Get Wishlist
     */
    getWishlist(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/wishlist`).subscribe((response: any) => {
                this.wishlist = response.data;
                this.onWishlistChange.next(this.wishlist);
                resolve(this.wishlist);
            }, reject);
        });
    }

    getUserlist(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/privateItemsuserlist`).subscribe((response: any) => {
                this.userProductList = response.data;
                this.onUserProductListChange.next(this.userProductList);
                resolve(this.userProductList);
            }, reject);
        });
    }

    /**
     * Get Selected Product
     */
    getSelectedProduct(): Promise<any[]> {
        if (this.productID) {
            return new Promise((resolve, reject) => {
                this._httpClient.get(`${environment.apiUrl}/items/` + this.productID).subscribe((response: any) => {
                    this.selectedProduct = response.data;
                    this.onSelectedProductChange.next(this.selectedProduct);
                    resolve(this.selectedProduct);
                }, reject);
            });
        }
    }

    /**
     * Get Related Products
     */
    getRelatedProducts(category?, created_by_id?): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/items/category-items/` + category + '/' + created_by_id).subscribe((response: any) => {
                this.relatedProducts = response.data;
                this.onRelatedProductsChange.next(this.relatedProducts);
                resolve(this.relatedProducts.sort(() => Math.random() - 0.5));
            }, reject);

        });
    }


    getProductsByCategory(category): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/items/category-items/` + category).subscribe((response: any) => {
                this.productList = response.data;
                this.onProductListChange.next(this.productList);
                resolve(this.productList);
            }, reject);
        });
    }

    getProductsBySearchText(searchText): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.apiUrl}/items/seracheditems/` + searchText).subscribe((response: any) => {
                this.productList = response.data;
                this.onProductListChange.next(this.productList);
                resolve(this.productList);
            }, reject);
        });
    }

    /**
     * Sort Product
     *
     * @param sortBy
     * @param productList
     */
    sortProduct(sortBy, productList) {
        let sortDesc = false;

        const sortByKey = (() => {
            if (sortBy === 'price-desc') {
                sortDesc = true;
                return 'dayCost';
            }
            if (sortBy === 'price-asc') {
                return 'dayCost';
            }
            sortDesc = true;
            return 'id';
        })();


        const sortedData = productList.sort(this.sortRef(sortByKey));
        if (sortDesc) sortedData.reverse();
        productList = sortedData;
        this.onProductListChange.next(productList);
        this.userProductList = sortedData;
        this.onUserProductListChange.next(this.userProductList);
    }


    /**
     * Filter items
     * @param priceRange
     * @param selectedCategories
     * @param rating
     */
    filterProduct(priceRange, selectedCategories, rating) {
        let sortedProductList = [];
        // this.productList = this.originalProductList;
        if (!selectedCategories || !selectedCategories.length) {
            sortedProductList = this.productList.filter(item => {
                return item.dayCost >= priceRange[0] && item.dayCost <= priceRange[1]
            });
        } else {

            // for (let i = 0; i < selectedCategories.length; i++) {
            //     this.productList.filter(item => {
            //         if (item.category === selectedCategories[i]
            //             && item.dayCost >= priceRange[0]
            //             && item.dayCost <= priceRange[1])
            //             sortedProductList.push(item);
            //     });
            // }
            sortedProductList = this.productList.filter(product => selectedCategories.some(cat => cat === product.category) && product.dayCost >= priceRange[0]
                && product.dayCost <= priceRange[1]);
        }

        this.onProductListChange.next(sortedProductList);

    }


    // filterProductByRange(priceRange) {
    //     let sortedProductList = [];
    //     sortedProductList = this.productList.filter(item => {
    //         return item.dayCost >= priceRange[0] && item.dayCost <= priceRange[1]
    //     });
    //
    //     // this.productList = sortedProductList;
    //     this.onProductListChange.next(sortedProductList);
    // }

    filterProductByRating(rating) {
        let sortedProductList = [];
        sortedProductList = this.productList.filter(item => {
            return item.rating >= rating;
        });

        this.onProductListChange.next(sortedProductList);
    }

    // filterProductByCategory(categories) {
    //     let sortedProductList = [];
    //     sortedProductList = this.productList.filter(product => categories.includes(product.category));
    //     this.productList = sortedProductList;
    //     this.onProductListChange.next(this.productList);
    // }

    resetFilter() {
        this.getProducts();
        // this.productList = this.originalProductList;
        // this.onProductListChange.next(this.productList);
    }

    /**
     * Add In Wishlist
     *
     * @param itemId
     */
    addToWishlist(itemId) {
        return new Promise<void>((resolve, reject) => {
            this._httpClient.post(`${environment.apiUrl}/wishlist/add-wishlist`, {
                itemId: itemId
            }).subscribe(response => {
                this.getWishlist();
                resolve();
            }, reject);
        });
    }

    /**
     * Remove From Wishlist
     *
     * @param itemId
     */
    removeFromWishlist(itemId: string) {
        return new Promise<void>((resolve, reject) => {
            this._httpClient.delete(`${environment.apiUrl}/wishlist/delete/` + itemId).subscribe((response: any) => {
                // setTimeout(() => {
                //     this._alertService.onSuccessMessage(
                //         this._translateService.instant(''),
                //         this._translateService.instant('')
                //     );
                // }, 500);
                this.getWishlist();
                resolve();
            }, reject);
        });
    }

    /**
     * remove item from user-store
     * @param id
     */
    removeFromUserProductList(id) {
        return new Promise<void>((resolve, reject) => {
            this._httpClient.delete(`${environment.apiUrl}/items/` + id).subscribe((response: any) => {
                // this.getProducts();

                resolve(response);
            }, reject);
        });
    }


    /**
     * upload and create new item / edit existing item
     * @param multipleImages
     * @param newItem
     */

    createNewItem(multipleImages, newItem) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            for (let img of multipleImages) {
                formData.append('files', img);
            }
            formData.append('data', JSON.stringify(newItem));
            this._httpClient.post<any>(`${environment.apiUrl}/create-new-item`, formData)
                .subscribe((res) => {
                    if (res.error) {
                        this._alertService.onErrorMessage(this._translateService.instant('authentication.general.onError.msg'), this._translateService.instant('authentication.ecommerce.createNewItem.title'));
                    } else {
                        setTimeout(() => {
                            this._alertService.onSuccessMessage('Congratulation ' + newItem?.created_by_name +
                                ' 🎉 ' + this._translateService.instant('authentication.ecommerce.createNewItem.onSuccessMsg_msg'),
                                this._translateService.instant('authentication.ecommerce.createNewItem.title'))
                        }, 800);
                        if (!newItem.isIbanSubmitted) {
                            setTimeout(() => {
                                this._alertService.onSuccessMessage(this._translateService.instant('authentication.iban.message'),
                                    this._translateService.instant('authentication.iban.title'))
                            }, 2200);
                        }
                        this._router.navigate(['account/my-store']);
                    }
                }, reject);
        });

    }


    updateItem(multipleImages: any[], newItem: any) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            for (let img of multipleImages) {
                formData.append('files', img);
            }
            formData.append('data', JSON.stringify(newItem));
            formData.append('id', this.productID);
            this._httpClient.post<any>(`${environment.apiUrl}/updateItem`, formData)
                .subscribe((res) => {
                    if (res.error) {
                        this._alertService.onErrorMessage(this._translateService.instant('authentication.general.onError.msg'), this._translateService.instant('authentication.ecommerce.updateItem.title'));
                    } else {
                        setTimeout(() => {
                            this._alertService.onSuccessMessage('Thank you' + newItem?.created_by_name +
                                this._translateService.instant('authentication.ecommerce.updateItem.onSuccessMsg_msg'),
                                this._translateService.instant('authentication.ecommerce.updateItem.title'))
                        }, 1000);
                        this._router.navigate(['account/my-store']);
                    }
                }, reject);
        });
    }


    /**
     * get rating Counter
     * @param option
     */
    getRatingCounter(option: number) {
        return this.productList.filter(item => {
            return item.rating >= option;
        })
    }


    /**
     *
     * @param item
     */
    setCartItem(item) {
        this.cartItem = item;
        if (localStorage.getItem('cartItem')) {
            localStorage.removeItem('cartItem');
        }
        localStorage.setItem('cartItem', JSON.stringify(item));
    }

    getItemCart() {
        let item = JSON.parse(localStorage.getItem('cartItem'));
        if (item) {
            this.cartItem = item;
        }
        return this.cartItem;
    }


}
